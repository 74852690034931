import Clarity from "@microsoft/clarity";

const initializeClarity = () => {
  const clarityProjectId = process.env.REACT_APP_CLARITY_PROJECT_ID;

  if (clarityProjectId) {
    Clarity.init(clarityProjectId);
    console.log(`Clarity initialized with Project ID: ${clarityProjectId}`);
  } else {
    console.error("Clarity Project ID is not defined!");
  }
};

export default initializeClarity;
