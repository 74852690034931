import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";

interface GoogleAutocompleteProps {
  valueloc?: string;

  className?: string;
  placeholder?: string;
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
  onCleared: () => void;
  onBlurred?: () => void;
}

export interface GoogleAutoCompleteRef {
  reset: () => void;
}

const GoogleAutoComplete = forwardRef<
  GoogleAutoCompleteRef,
  GoogleAutocompleteProps
>(
  (
    { valueloc, className, placeholder, onPlaceSelected, onCleared, onBlurred },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [autocomplete, setAutocomplete] =
      useState<google.maps.places.Autocomplete | null>(null);
    const [inputValue, setInputValue] = useState<string>(valueloc || "");
    const [placeSelected, setPlaceSelected] = useState(false);
    const [validating, setValidating] = useState(false); // Prevent premature validation

    useEffect(() => {
      if (valueloc !== undefined) {
        setInputValue(valueloc);
        if (inputRef.current) {
          inputRef.current.value = valueloc; // Sync input field
        }
      }
    }, [valueloc]);

    useEffect(() => {
      let autocompleteInstance: google.maps.places.Autocomplete | null = null;

      if (inputRef.current && window.google) {
        if (!autocomplete) {
          autocompleteInstance = new window.google.maps.places.Autocomplete(
            inputRef.current,
            {
              componentRestrictions: { country: "in" },
            }
          );

          // Add listener for place selection
          autocompleteInstance.addListener("place_changed", () => {
            const place = autocompleteInstance!.getPlace();
            if (place && place.geometry && place.name) {
              setInputValue(place.name); // Update input value
              setPlaceSelected(true); // Mark place as selected
              setValidating(false); // Stop pending validation
              onPlaceSelected(place); // Notify parent
            }
          });

          setAutocomplete(autocompleteInstance);
        }
      }
    }, [autocomplete, onPlaceSelected]);

    // Handle input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setInputValue(value);
      setPlaceSelected(false); // Reset place selection when user types
      if (!value) {
        onCleared(); // Notify parent on clear
      }
    };

    // Handle input blur
    const handleInputBlur = () => {
      if (validating) return; // Avoid duplicate validation during pending state

      setTimeout(() => {
        if (onBlurred && !placeSelected && inputValue.trim() === "") {
          onBlurred(); // Trigger validation if no place is selected and input is empty
        }
      }, 300); // Slight delay to ensure place_changed runs first
    };

    // Handle input focus to trigger autocomplete dropdown
    const handleInputFocus = () => {
      if (inputRef.current) {
        inputRef.current.dispatchEvent(new Event("keydown", { bubbles: true })); // Manually trigger dropdown
      }
    };

    // Clear input and reset state
    const handleClear = () => {
      setInputValue("");
      setPlaceSelected(false);
      setValidating(false); // Stop pending validation
      onCleared();
      if (inputRef.current) {
        inputRef.current.value = ""; // Clear the input field
      }
    };

    // Reset function exposed to parent
    const reset = () => {
      setInputValue("");
      setPlaceSelected(false);
      setValidating(false);
      if (inputRef.current) {
        inputRef.current.value = "";
      }
      onCleared();
    };

    // Expose reset method to parent
    useImperativeHandle(ref, () => ({
      reset,
    }));

    return (
      <div className="relative w-full">
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          placeholder={placeholder || "Enter Location"}
          className={`w-full pr-[33px] ${className}`}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        {/* Clear button */}
        {inputValue && (
          <button
            type="button"
            className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1 text-grayText text-[12px]"
            onClick={handleClear}
          >
            ✕
          </button>
        )}
      </div>
    );
  }
);

export default GoogleAutoComplete;
