import "./SearchBar.scss";
import { useCallback, useState } from "react";
import { ReactComponent as SearchIcon } from "./../../assets/icons/search-icon.svg";
import { debounce } from "lodash";

interface SearchProps {
  onSearch: (query: string) => void; // Function to handle search logic
  delay?: number; // Optional delay time for debounce (default 300ms)
  showClearButton?: boolean;
  placeholder?: string;
}

const SearchBar: React.FC<SearchProps> = ({
  onSearch,
  delay = 300,
  showClearButton = false,
  placeholder,
}) => {
  const [inputFocus, setInputFocus] = useState<boolean>(false);

  const [query, setQuery] = useState("");

  // useCallback is used to memoize the debounced search function
  const debouncedSearch = useCallback(
    debounce((nextQuery: string) => onSearch(nextQuery), delay),
    [onSearch, delay]
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(value);
  };

  return (
    <div className="search-bar-component">
      <input
        type={showClearButton ? "search" : "text"}
        value={query}
        onChange={handleChange}
        placeholder={placeholder ? placeholder : "Search"}
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
      />
      <button className={inputFocus ? "focused-btn" : "not-focused-btn"}>
        <SearchIcon />
      </button>
    </div>
  );
};

export default SearchBar;
