export const urlQueryBuilder = (params: Record<string, any>) => {
  const queryString = new URLSearchParams();

  // Iterate over the object and append non-null values to the query string
  for (const key in params) {
    if (
      params[key] !== null &&
      params[key] !== undefined &&
      params[key] !== ""
    ) {
      queryString.append(key, params[key]);
    }
  }

  return queryString.toString();
};
