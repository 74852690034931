import "./Modal.scss"; // Import the CSS file for styling
import React, { ReactNode } from "react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-icon.svg";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
  closeOnModalOverlayClick?: boolean;
  className?: string;
  headerButtons?: ReactNode; // New prop for custom header buttons
  showCloseButton?: boolean; // Optional prop to control close button visibility
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  title,
  closeOnModalOverlayClick = false,
  className,
  headerButtons,
  showCloseButton = true, // Default to showing close button
}) => {
  if (!isOpen) return null;

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  const handleModalOverlayClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (closeOnModalOverlayClick) onClose();
  };

  return (
    <div className="modal-component">
      <div className="modal-overlay" onClick={handleModalOverlayClick}>
        <div
          className={`modal-content ${className ? className : ""}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="modal-header">
            <div className="header-name">{title}</div>
            <div className="header-buttons">
              {headerButtons}
              {showCloseButton && (
                <button className="modal-close" onClick={handleClose}>
                  <CloseIcon className="text-grayText" />
                </button>
              )}
            </div>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
