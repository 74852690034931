import { Outlet } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import Header from "../components/Header/Header";
import { useEffect, useState } from "react";

const SidebarLayout: React.FC = () => {
  const [currentSegment, setCurrentSegment] = useState<string>("New");
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    () => JSON.parse(localStorage.getItem("isSidebarCollapsed") || "false") // Load state from localStorage
  );
  const handleSegmentChange = (segment: string) => {
    setCurrentSegment(segment);
  };
  const toggleSidebar = () => {
    setIsCollapsed((prev) => {
      const newValue = !prev;
      localStorage.setItem("isSidebarCollapsed", JSON.stringify(newValue)); // Save state to localStorage
      return newValue;
    });
  };
  useEffect(() => {
    // console.log("SidebarLayout currentSegment:", currentSegment); // Confirm segment change
  }, [currentSegment]);
  return (
    <div style={styles.layoutContainer}>
      <SideBar isCollapsed={isCollapsed} onToggle={toggleSidebar} />
      <div
        style={{
          ...styles.content,
          transition: "margin-left 0.3s ease",
        }}
      >
        <Header
          onSegmentChange={handleSegmentChange}
          currentSegment={currentSegment}
        ></Header>
        <div className="main_area_inset_shadow">
          <Outlet
            context={{ currentSegment, setSegment: handleSegmentChange }}
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  layoutContainer: {
    display: "flex",
  },
  content: {
    flex: 1,
  },
};

export default SidebarLayout;
