import "./Jobs.scss";
import NewJob from "../../components/Jobs/NewJob";
import ExistingJob from "../../components/Jobs/ExistingJob";
import { useOutletContext } from "react-router-dom";
import { JobProvider } from "../../contexts/JobContext";
import React from "react";

interface JobsProps {
  currentSegment: string;
  setSegment: (segment: string) => void;
  status?: string;
}

// export default function Jobs() {
const Jobs: React.FC = () => {
  const { currentSegment, setSegment, status } = useOutletContext<JobsProps>();

  return (
    <JobProvider>
      <div className="jobs-page">
        <div className="jobs-container">
          <div className={currentSegment === "New" ? "visible" : "hidden"}>
            <NewJob setSegment={setSegment} />
          </div>
          <div className={currentSegment === "Existing" ? "visible" : "hidden"}>
            <ExistingJob currentSegment={currentSegment} status={status} />
          </div>
        </div>
      </div>
    </JobProvider>
  );
};

export default Jobs;
