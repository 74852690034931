import "./LiveTracking.scss";
import React, { useEffect, useRef, useState } from "react";
import {
  ContainerStats,
  JobBreakUpDetail,
  JobOrder,
} from "../../interfaces/job.interface";
import {
  containerSizeStringToNumber,
  dateFromNow,
  formatEpochDate,
  getContainerStatsFromJobOrder,
  getContainerStatus,
  mergeDouble20s,
  transformTruckNumber,
} from "../../utils/general";
import { ReactComponent as TruckIcon } from "../../assets/icons/truck.svg";
import { ReactComponent as StatusIcon } from "../../assets/icons/job-status-icon.svg";
import JobService from "../../services/JobService";
import JobStatus from "../Jobs/JobStatus";
import { ReactComponent as CicleIcon } from "../../assets/icons/circle.svg";
import { DriverLocation } from "../../interfaces/driver.interface";

interface LiveTrackingProps {
  job: JobOrder;
  jobBreakupDetailId: number;
}

const LiveTracking: React.FC<LiveTrackingProps> = ({
  job,
  jobBreakupDetailId,
}) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [distance, setDistance] = useState<string>("");
  const [estimatedTime, setEstimatedTime] = useState<string>("");
  const [refreshedDate, setRefreshedDate] = useState<number>();
  const [containerStats, setContainerStats] = useState<ContainerStats>();
  const [selectedJobBreakUpDetails, setSelectedJobBreakUpDetails] =
    useState<JobBreakUpDetail>();
  const [selectedDriverId, setSelectedDriverId] = useState<number | null>();
  const [selectedDriverLocation, setSelectedDriverLocation] =
    useState<DriverLocation>();
  const [mergedJob, setMergedJob] = useState<JobOrder>(mergeDouble20s(job));
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const mapRef = useRef<google.maps.Map | null>(null);
  const truckMarkerRef = useRef<google.maps.Marker | null>(null);
  const infoWindowRef = useRef<google.maps.InfoWindow | null>(null);
  let driverDirectionsRenderer: google.maps.DirectionsRenderer | null = null;
  const refreshInterval = 30 * 1000; // 30 seconds

  const initializeDriverDirectionsRenderer = (map: google.maps.Map) => {
    console.log("initializeDriverDirectionsRenderer");
    if (!driverDirectionsRenderer) {
      driverDirectionsRenderer = new google.maps.DirectionsRenderer({
        map,
        preserveViewport: true,
        suppressMarkers: true, // Use custom markers
        polylineOptions: {
          strokeColor: "#027cb5",
          strokeWeight: 0,
        },
      });
    } else {
      console.log("already initializeDriverDirectionsRenderer");
    }
  };

  const setJobBreakupDetail = (jobBreakupDetailId: number) => {
    for (const jobBreakup of mergedJob.jobBreakups) {
      for (const detail of jobBreakup.jobBreakUpDetails) {
        if (detail.isDoubleTwenty) {
          if (
            detail.doubleTwentyDetails?.some(
              (dtObject) => dtObject.jobBreakupDetailId === jobBreakupDetailId
            )
          ) {
            setSelectedJobBreakUpDetails(detail);
          }
        } else {
          if (detail.jobBreakupDetailId === jobBreakupDetailId) {
            setSelectedJobBreakUpDetails(detail);
          }
        }
      }
    }
  };

  const getDriverIdFromJobBreakupDetailId = (
    jobBreakupDetailId: number
  ): number | null => {
    for (const jobBreakup of job.jobBreakups) {
      for (const detail of jobBreakup.jobBreakUpDetails) {
        if (detail.jobBreakupDetailId === jobBreakupDetailId) {
          return detail.driverIdName?.id ?? null;
        }
      }
    }
    return null; // Return null if the ID is not found
  };

  // Simulating real-time driver location updates
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (
  //       selectedDriverLocation?.currentLat &&
  //       selectedDriverLocation.currentLong
  //     ) {
  //       const currentDriverLocation: DriverLocation = {
  //         currentLat:
  //           selectedDriverLocation?.currentLat + (Math.random() - 0.5) * 0.001,
  //         currentLong:
  //           selectedDriverLocation?.currentLong + (Math.random() - 0.5) * 0.001,
  //       };
  //       setSelectedDriverLocation(currentDriverLocation);
  //     }
  //   }, 2000);

  //   return () => clearInterval(interval);
  // }, [selectedDriverLocation]);

  const refreshJobDetails = async (driverId: number) => {
    if (job.jobStatus !== "COMPLETED") {
      setRefreshedDate(Date.now());
      try {
        const driverDetails = await JobService.trackDriver(driverId);
        const currentDriverLocation: DriverLocation = {
          currentLat: driverDetails.data.currentLat,
          currentLong: driverDetails.data.currentLong,
        };

        setSelectedDriverLocation((prevLocation) => {
          if (
            currentDriverLocation.currentLat !== null &&
            currentDriverLocation.currentLong !== null &&
            (!prevLocation || // No location set yet
              prevLocation.currentLat !== currentDriverLocation.currentLat ||
              prevLocation.currentLong !== currentDriverLocation.currentLong)
          ) {
            console.log("Updating location:", currentDriverLocation);
            return currentDriverLocation;
          } else {
            console.log("No change in location.");
            return prevLocation; // Keep the previous location
          }
        });
      } catch (error) {
        console.error("Error refreshing driver details", error);
      }
    } else {
      console.log("Job has been completed!");
    }
  };

  useEffect(() => {
    if (!map) {
      console.error("Map instance not available.");
      return;
    }

    if (
      !selectedDriverLocation ||
      selectedDriverLocation.currentLat === null ||
      selectedDriverLocation.currentLong === null
    ) {
      console.warn("Invalid driver location data:", selectedDriverLocation);
      return;
    }

    const truckPosition = {
      lat: parseFloat(selectedDriverLocation.currentLat),
      lng: parseFloat(selectedDriverLocation.currentLong),
    };

    // Clear the previous marker if it exists
    if (truckMarkerRef.current) {
      truckMarkerRef.current.setMap(null);
    }

    // Create or update the truck marker
    truckMarkerRef.current = new google.maps.Marker({
      position: truckPosition,
      map,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 8,
        fillColor: "#19b5fe",
        fillOpacity: 1,
        strokeWeight: 2,
        strokeColor: "#fff",
      },
      title: "Truck",
    });

    truckMarkerRef.current.addListener("click", () => {
      if (infoWindowRef.current) {
        infoWindowRef.current.open(map, truckMarkerRef.current);
      }
    });

    // Update or create the InfoWindow
    const updateInfoWindowContent = () => {
      if (infoWindowRef.current) {
        infoWindowRef.current.setContent(`
          <div class="custom-info-window">
            <h3 class="header">
              ${transformTruckNumber(
                selectedJobBreakUpDetails?.truckIdName.name
              )}
            </h3>
            <div class="content">
              <strong>Driver</strong>
              <span>${selectedJobBreakUpDetails?.driverIdName.name}</span>
              <strong>Container</strong>
              <span>
                ${
                  selectedJobBreakUpDetails?.isDoubleTwenty
                    ? selectedJobBreakUpDetails.doubleTwentyDetails
                        ?.map((detail) => detail.containerNumber)
                        .join(", ")
                    : selectedJobBreakUpDetails?.containerNumber
                }
              </span>
              <strong>Picked</strong>
              <span>${dateFromNow(selectedJobBreakUpDetails?.startDate)}</span>
            </div>
          </div>
        `);
      }
    };

    if (!infoWindowRef.current) {
      infoWindowRef.current = new google.maps.InfoWindow({
        content: "", // Set empty initially, then update
        maxWidth: 300,
      });
    }

    updateInfoWindowContent(); // Update content initially
    infoWindowRef.current.open(map, truckMarkerRef.current);

    // Pan the map if the truck moves significantly
    setTimeout(() => {
      const currentMapCenter = map.getCenter();
      if (
        currentMapCenter &&
        google.maps.geometry.spherical.computeDistanceBetween(
          currentMapCenter,
          new google.maps.LatLng(truckPosition)
        ) > 50
      ) {
        map.panTo(truckPosition);
      }
    }, 150);

    updateDriverDirections(selectedDriverLocation);
  }, [selectedDriverLocation, selectedJobBreakUpDetails, map]);

  const updateDriverDirections = (selectedDriverLocation: DriverLocation) => {
    if (
      selectedDriverLocation.currentLat &&
      selectedDriverLocation.currentLong
    ) {
      const truckPosition = {
        lat: parseFloat(selectedDriverLocation.currentLat),
        lng: parseFloat(selectedDriverLocation.currentLong),
      };
      const destinationPosition = {
        lat: parseFloat(job.destLatitude),
        lng: parseFloat(job.destLongitude),
      };
      if (map) {
        initializeDriverDirectionsRenderer(map);
        if (driverDirectionsRenderer) {
          const directionsService = new google.maps.DirectionsService();
          directionsService.route(
            {
              origin: truckPosition,
              destination: destinationPosition,
              travelMode: google.maps.TravelMode.DRIVING,
              avoidHighways: false,
              avoidTolls: false,
              drivingOptions: {
                departureTime: new Date(Date.now()),
                trafficModel: google.maps.TrafficModel.BEST_GUESS,
              },
            },
            (result, status) => {
              if (status === google.maps.DirectionsStatus.OK && result) {
                driverDirectionsRenderer!.setDirections(result);

                const leg = result.routes[0].legs[0];
                if (leg) {
                  console.log(leg);
                  setDistance(leg.distance?.text || "N/A");
                  // setEstimatedTime(leg.duration?.text || "N/A");
                  setEstimatedTime(leg.duration_in_traffic?.text || "N/A");
                }
              } else {
                console.error(`Error fetching route: ${status}`);
              }
            }
          );
        }
      }
    }
  };

  useEffect(() => {
    if (job) {
      if (jobBreakupDetailId) {
        setJobBreakupDetail(jobBreakupDetailId);
        setSelectedDriverId(
          getDriverIdFromJobBreakupDetailId(jobBreakupDetailId)
        );
      }
      setMergedJob(mergeDouble20s(job));
      setContainerStats(getContainerStatsFromJobOrder(job));
      loadMap();
    }
  }, [job]);

  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    if (
      selectedDriverId !== null &&
      selectedDriverId !== undefined &&
      job.jobStatus !== "COMPLETED"
    ) {
      refreshJobDetails(selectedDriverId);
      intervalRef.current = setInterval(
        () => refreshJobDetails(selectedDriverId),
        refreshInterval
      );
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [selectedDriverId]);

  const loadMap = () => {
    if (job) {
      const mapOptions: google.maps.MapOptions = {
        streetViewControl: false,
        fullscreenControl: false,
        zoom: 8,
        center: {
          lat: parseFloat(job.sourceLatitude),
          lng: parseFloat(job.sourceLongitude),
        },
        styles: [
          {
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative.land_parcel",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "administrative.neighborhood",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      };

      if (window.google) {
        const mapInstance = new window.google.maps.Map(
          document.getElementById("map") as HTMLElement,
          mapOptions
        );

        mapRef.current = mapInstance;

        setMap(mapInstance);

        const sourcePosition = {
          lat: parseFloat(job.sourceLatitude),
          lng: parseFloat(job.sourceLongitude),
        };
        const destinationPosition = {
          lat: parseFloat(job.destLatitude),
          lng: parseFloat(job.destLongitude),
        };

        const createMarkerWithLabel = (
          position: google.maps.LatLngLiteral,
          title: string,
          label: string,
          imageUrl?: string,
          iconColor?: string
        ) => {
          const markerOptions: google.maps.MarkerOptions = {
            position,
            map: mapInstance,
            title,
            label,
            animation: google.maps.Animation.DROP,
          };

          markerOptions.label = {
            text: label,
            // color: "#59585b",
            color: "#000",
            fontWeight: "400",
          };

          if (iconColor) {
            markerOptions.icon = {
              path: "M10.5 0.368164C4.97662 0.368164 0.5 4.84586 0.5 10.3682C0.5 13.4471 1.89154 16.1999 4.07974 18.0348L10.5 24.4562L16.9203 18.0359C19.1085 16.2011 20.5 13.4482 20.5 10.3692C20.5 4.84586 16.0231 0.368164 10.5 0.368164ZM10.5 17.0312C6.99332 17.0312 4.15067 14.1885 4.15067 10.6818C4.15067 7.17624 6.99332 4.33359 10.5 4.33359C14.0067 4.33359 16.8493 7.17624 16.8493 10.6829C16.8493 14.1885 14.0067 17.0312 10.5 17.0312Z",
              fillColor: iconColor,
              fillOpacity: 1.0,
              strokeColor: "#000000",
              strokeWeight: 1,
              scale: 1,
              anchor: new google.maps.Point(12, 24),
            };
          }

          if (imageUrl) {
            markerOptions.icon = {
              url: imageUrl,
              scaledSize: new google.maps.Size(30, 30), // Adjust size as needed
            };
          }
          new window.google.maps.Marker(markerOptions);
        };

        createMarkerWithLabel(
          sourcePosition,
          job.source,
          job.source,
          "",
          "#cbd2d9"
        );

        createMarkerWithLabel(
          destinationPosition,
          job.destination,
          job.destination,
          "",
          "#19b5fe"
        );

        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer({
          suppressMarkers: true,
          polylineOptions: {
            strokeColor: "#000",
            // strokeColor: "#027cb5",
            strokeWeight: 4,
          },
        });
        directionsRenderer.setMap(mapInstance);

        directionsService.route(
          {
            origin: sourcePosition,
            destination: destinationPosition,
            travelMode: google.maps.TravelMode.DRIVING,
            provideRouteAlternatives: false,
            avoidHighways: false,
            avoidTolls: false,
          },
          (result, status) => {
            if (status === google.maps.DirectionsStatus.OK && result) {
              directionsRenderer.setDirections(result);

              // distance and estimated time from google api
              const leg = result.routes[0].legs[0];
              if (leg) {
                setDistance(leg.distance?.text || "N/A");
                // setEstimatedTime(leg.duration?.text || "N/A");
                setEstimatedTime(leg.duration_in_traffic?.text || "N/A");
              }
            } else {
              console.error(`Error fetching directions: ${status}`, result);
            }
          }
        );

        const trafficLayer = new google.maps.TrafficLayer();
        trafficLayer.setMap(mapInstance);
      }
    }
  };

  const handleTrackSelection = (containerDetails: JobBreakUpDetail) => {
    // console.log("handleTrackSelection");
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    const newDriverId = containerDetails.driverIdName?.id;
    setJobBreakupDetail(containerDetails.jobBreakupDetailId);
    setSelectedDriverId(newDriverId);
  };

  const isTracking = (jobBreakupDetailId: number) => {
    // console.log(selectedJobBreakUpDetails);
    if (selectedJobBreakUpDetails?.isDoubleTwenty) {
      if (
        selectedJobBreakUpDetails.doubleTwentyDetails?.some(
          (dtObject) => dtObject.jobBreakupDetailId === jobBreakupDetailId
        )
      )
        return true;
      return false;
    } else {
      if (selectedJobBreakUpDetails?.jobBreakupDetailId === jobBreakupDetailId)
        return true;
      return false;
    }
  };

  return (
    <div className="live-tracking-component">
      <div id="map" className="map_style" style={containerStyle}></div>
      <div className="live-stats bg-card">
        {/* Job Header and Job Details */}
        <div className="flex justify-between items-center title">
          <div className="left job_id">#{job.jobCode}</div>
          <div className={`job_status ${job.jobStatus}`}>
            {/* update this once the job status issue is fixed */}
            {/* <JobStatus jobStatus={job.jobStatus} /> */}
            {job.jobStatus === "COMPLETED" ? (
              <JobStatus jobStatus={job.jobStatus} />
            ) : (
              <JobStatus jobStatus={"IN_PROGRESS"} />
            )}
          </div>
        </div>

        <div className="src_dest_cover">
          <div className="src-dest">
            <div className="src">
              <CicleIcon className="source_icon" />
              <div
                className="source_name truncate-two-lines"
                title={job.source}
              >
                {job.source}
              </div>
            </div>
            <div className="route"></div>
            <div className="dest">
              <CicleIcon className="destination_icon" />
              <div
                className="destination_name truncate-two-lines"
                title={job.destination}
              >
                {job.destination}
              </div>
            </div>
          </div>
        </div>

        {/* Job Status Component */}
        <div className="job_details">
          <div className="text-left text-grayText status_title">Job Status</div>
          <div className="flex justify-between items-center">
            <span className="total_dropped_containers">
              {containerStats &&
                Math.round(containerStats.totalJobStatusPercentage)}
              %
            </span>
            <span className="percentage">
              <span className="total_dropped_containers">
                {containerStats?.totalDroppedContainers}{" "}
              </span>

              <span className="total_containers">
                / {containerStats?.totalContainer} dropped
              </span>
            </span>
          </div>
          {/* Progress Bar */}
          <div className="w-full h-3 job_progress_cover">
            <div
              className="h-3 job_progress"
              style={{ width: `${containerStats?.totalJobStatusPercentage}%` }}
            ></div>
          </div>
          {/* {refreshedDate && <div>{dateFromNow(refreshedDate)}</div>} */}
        </div>

        {/* Container List */}
        <div className="truck_list">
          {mergedJob.jobBreakups.flatMap((breakup, index) => {
            const previousCount = job.jobBreakups
              .slice(0, index) // Only consider breakups before the current one
              .reduce(
                (acc, prevBreakup) =>
                  acc +
                  prevBreakup.jobBreakUpDetails.filter(
                    (detail) => detail.containerNumber
                  ).length,
                0
              );

            return breakup.jobBreakUpDetails
              .filter(
                (containerDetail) =>
                  containerDetail.containerNumber &&
                  containerDetail.truckIdName?.name &&
                  getContainerStatus(containerDetail) === "Picked"
              )
              .map((containerDetail, detailIndex) => (
                <div
                  key={previousCount + detailIndex + 1}
                  className={`truck_card mb-4 ${
                    isTracking(containerDetail.jobBreakupDetailId)
                      ? "selected_truck"
                      : ""
                  }`}
                >
                  <div className="flex justify-between items-center header">
                    <div className="flex items-center">
                      <TruckIcon className="text-[#a3a8b4]" />
                      <span className="ml-[6px] truck_no">
                        {containerDetail.truckIdName
                          ? transformTruckNumber(
                              containerDetail.truckIdName.name
                            )
                          : "N/A"}
                      </span>
                    </div>

                    {isTracking(containerDetail.jobBreakupDetailId) ? (
                      <div className="tracking-style">
                        <StatusIcon></StatusIcon>
                        <span>Tracking</span>
                      </div>
                    ) : (
                      <div
                        className="link-style"
                        onClick={() => handleTrackSelection(containerDetail)}
                      >
                        Track
                      </div>
                    )}
                  </div>

                  {/* Grid Layout for Container Details */}
                  <div className="container_details grid grid-cols-2 gap-2  text-transporterText">
                    <div className="label">
                      <span>Container No</span>
                    </div>
                    <div>
                      <span className="value">
                        {containerDetail.isDoubleTwenty
                          ? containerDetail.doubleTwentyDetails
                              ?.map((detail) => detail.containerNumber)
                              .join(", ")
                          : containerDetail.containerNumber}
                      </span>
                    </div>

                    <div className="label">
                      <span>Size & Type</span>
                    </div>
                    <div>
                      <span className="value">
                        {containerDetail.isDoubleTwenty
                          ? containerDetail.doubleTwentyDetails
                              ?.map(
                                (detail) =>
                                  `${containerSizeStringToNumber(
                                    detail.containerSize
                                  )} ${detail.containerType}`
                              )
                              .join(", ")
                          : containerSizeStringToNumber(breakup.containerSize) +
                            " " +
                            breakup.containerType}
                      </span>
                    </div>

                    <div className="label">
                      <span>Picked at</span>
                    </div>
                    <div className="value">
                      <span>{formatEpochDate(containerDetail.startDate)}</span>
                    </div>

                    {isTracking(containerDetail.jobBreakupDetailId) && (
                      <>
                        <div className="label">
                          <span>Distance to drop</span>
                        </div>
                        <div className="value">
                          <span>{distance}</span>
                        </div>
                        <div className="label">
                          <span>ETA</span>
                        </div>
                        <div className="value">
                          <span>{estimatedTime}</span>
                        </div>

                        {refreshedDate && (
                          <>
                            <div className="label">
                              <span>Last synced at</span>
                            </div>
                            <div className="value">
                              <span>{formatEpochDate(refreshedDate)}</span>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ));
          })}
        </div>
      </div>
    </div>
  );
};

export default LiveTracking;

const containerStyle = {
  width: "100%",
  height: "100%",
};
