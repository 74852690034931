import React, { useEffect, useState } from "react";
import "./SegmentButton.scss";

interface SegmentButtonProps {
  segments: string[];
  onSegmentChange?: (activeSegment: string) => void;
  currentSegment: string;
}

const SegmentButton: React.FC<SegmentButtonProps> = ({
  segments,
  onSegmentChange,
  currentSegment,
}) => {
  const [activeIndex, setActiveIndex] = useState(
    segments.indexOf(currentSegment)
  );

  useEffect(() => {
    const newIndex = segments.indexOf(currentSegment);
    if (newIndex !== -1) {
      setActiveIndex(newIndex);
    }
  }, [currentSegment, segments]);

  const handleSegmentClick = (index: number) => {
    setActiveIndex(index);
    if (onSegmentChange) {
      onSegmentChange(segments[index]);
    }
  };

  return (
    <div className="segment-cover inline-flex  rounded-md overflow-hidden">
      {segments.map((segment, index) => (
        <button
          key={index}
          onClick={() => handleSegmentClick(index)}
          className={`segment-btn px-4 py-2 transition-colors duration-200 min-w-[130px] h-[40px] ${
            index === activeIndex ? "active" : "inactive"
          }`}
        >
          {segment}
        </button>
      ))}
    </div>
  );
};

export default SegmentButton;
