import moment from "moment";
import {
  ContainerStats,
  EnrichedJobOrder,
  JobBreakUpDetail,
  JobOrder,
} from "../interfaces/job.interface";

export const containerSizeToString = (num: number) => {
  switch (num) {
    case 20:
      return "TWENTY";
    case 40:
      return "FORTY";
    case 45:
      return "FORTY_FIVE";
    default:
      return "Unknown";
  }
};

export const containerSizeStringToNumber = (str: string) => {
  switch (str) {
    case "TWENTY":
      return 20;
    case "FORTY":
      return 40;
    case "FORTY_FIVE":
      return 45;
    default:
      return null;
  }
};

export const formatEpochDate = (epoch: number) => {
  return moment(epoch).format("DD/MM/YY hh:mm A");
};

export const formatIsoDate = (ISOFormat: string) => {
  return moment(ISOFormat).format("DD/MM/YY hh:mm A");
};

export const dateFromNow = (date: string | number) => {
  return moment(date).fromNow();
};

export const calculateCycleTime = (startDate: number, endDate: number) => {
  if (!startDate || !endDate || isNaN(startDate) || isNaN(endDate)) {
    return "";
  }
  const start = moment(startDate);
  const end = moment(endDate);
  const totalMinutes = end.diff(start, "minutes");
  let difference;
  let unit;

  if (totalMinutes < 60) {
    difference = totalMinutes; // Minutes
    unit = "minute" + (difference !== 1 ? "s" : "");
  } else if (totalMinutes < 1440) {
    difference = Math.floor(totalMinutes / 60);
    unit = "hour" + (difference !== 1 ? "s" : "");
  } else {
    difference = Math.floor(totalMinutes / 1440);
    unit = "day" + (difference !== 1 ? "s" : "");
  }

  return `${difference} ${unit}`;
};

export const isDateLessThanOneMinute = (dateString: string) => {
  const inputDate = new Date(dateString);
  const currentDate = new Date();

  // Calculate the difference in milliseconds
  const differenceInMillis = currentDate.getTime() - inputDate.getTime();

  // Convert milliseconds to minutes and check if it's less than 1 minute
  return differenceInMillis < 60 * 1000;
};

export const getContainerStatsFromJobOrder = (job: JobOrder) => {
  const stats: ContainerStats = {
    totalContainer: 0,
    totalAssigned: 0,
    pendingToAssign: 0,
    totalDroppedContainers: 0,
    totalJobStatusPercentage: 0,
  };

  // Calculate total containers
  stats.totalContainer = job.jobBreakups.reduce(
    (total, breakup) => total + breakup.count,
    0
  );

  // Loop through jobBreakups and jobBreakUpDetails
  job.jobBreakups.forEach((breakup) => {
    breakup.jobBreakUpDetails.forEach((container: JobBreakUpDetail) => {
      // Count assigned containers
      if (container.containerNumber) {
        stats.totalAssigned++;
      } else {
        stats.pendingToAssign++;
      }

      // Count dropped containers (both startDate and endDate must be non-null)
      if (container.startDate && container.endDate) {
        stats.totalDroppedContainers++;
      }
    });
  });

  // Calculate job status percentage based on dropped containers
  if (stats.totalContainer > 0) {
    stats.totalJobStatusPercentage =
      (stats.totalDroppedContainers / stats.totalContainer) * 100;
  }

  return stats;
};

export const transformTruckNumber = (truckNumber: string) => {
  // Remove any dashes or spaces first
  truckNumber = truckNumber.replace(/[-\s]/g, "");

  // Define regex patterns for 9- and 10-character formats
  const regex10 = /^([A-Z\d]{2})([A-Z\d]{2})([A-Z\d]{2})([A-Z\d]{4})$/i; // 10 characters: AA AA AA AAAA
  const regex9 = /^([A-Z\d]{2})([A-Z\d]{2})([A-Z\d]{1})([A-Z\d]{4})$/i; // 9 characters: AA AA A AAAA

  let match;

  // Check if the truck number has exactly 10 characters
  match = truckNumber.match(regex10);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}-${match[4]}`.toUpperCase();
  }

  // Check if the truck number has exactly 9 characters
  match = truckNumber.match(regex9);
  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}-${match[4]}`.toUpperCase();
  }

  // If no match is found, return the original truck number
  return truckNumber.toUpperCase();
};

export const getContainerStatus = (containerDetail: JobBreakUpDetail) => {
  const { startDate, endDate } = containerDetail;

  if (!startDate) {
    return "Unpicked";
  } else if (startDate && !endDate) {
    return "Picked";
  } else if (startDate && endDate) {
    return "Dropped";
  }

  // Default case if needed
  return "Unknown";
};

export const mergeDouble20s = (job: JobOrder): JobOrder => {
  const mergedJobBreakups = job.jobBreakups.map((jobBreakup) => {
    const mergedDetailsMap: Record<string, JobBreakUpDetail> = {};
    jobBreakup.jobBreakUpDetails.forEach((detail) => {
      const key = `${detail.containerSize}-${detail.startDate}-${detail.truckIdName?.id}-${detail.driverIdName?.id}`;
      if (mergedDetailsMap[key]) {
        mergedDetailsMap[key].isDoubleTwenty = true;
        mergedDetailsMap[key].doubleTwentyDetails = [
          {
            jobBreakupDetailId: mergedDetailsMap[key].jobBreakupDetailId,
            containerNumber: mergedDetailsMap[key].containerNumber,
            containerSize: mergedDetailsMap[key].containerSize,
            containerType: mergedDetailsMap[key].containerType,
          },
          {
            jobBreakupDetailId: detail.jobBreakupDetailId,
            containerNumber: detail.containerNumber,
            containerSize: detail.containerSize,
            containerType: detail.containerType,
          },
        ];
      } else {
        mergedDetailsMap[key] = { ...detail };
      }
    });
    return {
      ...jobBreakup,
      jobBreakUpDetails: Object.values(mergedDetailsMap),
    };
  });
  return {
    ...job,
    jobBreakups: mergedJobBreakups,
  };
};

// export const enrichJobWithDetails = (jobs: JobOrder[]): EnrichedJobOrder[] => {
//   return jobs.map((job) => {
//     // Collect transporter and container details
//     const transporterDetailsSet = new Set<string>();
//     const containerDetails: string[] = [];

//     job.jobBreakups?.forEach((jobBreakup) => {
//       jobBreakup.jobBreakUpDetails?.forEach((detail) => {
//         if (detail.transporterIdName?.name) {
//           transporterDetailsSet.add(detail.transporterIdName.name); // Add to Set for uniqueness
//         }
//         if (detail.containerNumber) {
//           containerDetails.push(
//             `${detail.containerNumber} (${detail.containerSize} ${detail.containerType})`
//           );
//         }
//       });
//     });

//     // Convert Set to array for transporterDetails
//     return {
//       ...job,
//       transporterDetails: Array.from(transporterDetailsSet),
//       containerDetails,
//     };
//   });
// };
