import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

interface GenerateExcelOptions {
  fileName?: string; // Optional file name (default: "data.xlsx")
  download?: boolean; // Optional flag to trigger download (default: true)
}

/**
 * Generates an Excel sheet from given headers and data.
 *
 * @param headers - Array of header strings.
 * @param data - Array of objects, where each object represents a row.
 * @param options - Optional settings for file name and download.
 * @returns A Promise that resolves when the file is generated (and downloaded, if applicable).
 */
export const generateExcelSheet = (
  headers: string[],
  data: Array<{ [key: string]: any }>,
  options?: GenerateExcelOptions
): Promise<void> => {
  return new Promise((resolve, reject) => {
    try {
      const { fileName = "data.xlsx", download = true } = options || {};

      // Map headers to a row of keys for consistency
      const formattedData = data.map((entry) =>
        headers.reduce((acc, header) => {
          acc[header] = entry[header] || ""; // Use empty string if data is missing
          return acc;
        }, {} as { [key: string]: any })
      );

      // Create a new workbook and worksheet
      const worksheet = XLSX.utils.json_to_sheet(formattedData, {
        header: headers,
      });
      const workbook = XLSX.utils.book_new();

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Write the workbook to a binary string
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });

      // Convert the binary string to a Blob
      const excelBlob = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Trigger download if the download option is enabled
      if (download) {
        saveAs(excelBlob, fileName);
      }

      resolve(); // Resolve the promise when the file is generated/downloaded
    } catch (error) {
      reject(error); // Reject the promise in case of an error
    }
  });
};
