import "./NewJob.scss";
import React, { useState, useEffect, useRef } from "react";
import Vessel from "../Vessel/Vessel";
import Transporter from "./../Transporter";
import Preview from "./Preview";
import CustomSelect, { CustomSelectRef } from "../CoreComponents/CustomSelect";
import GoogleAutoComplete, {
  GoogleAutoCompleteRef,
} from "../GoogleAutoComplete/GoogleAutoComplete";
import * as yup from "yup";
import { useMetadata } from "../../contexts/MetadataContext";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import {
  Job,
  TransporterJobHandlingRequest,
} from "../../interfaces/job.interface";
import { Inventory } from "../../interfaces/inventory.interface";
import { useLoading } from "../../contexts/LoadingContext";
import { showToast } from "../Toaster";
import { useJobContext } from "../../contexts/JobContext";
import JobService from "../../services/JobService";
import Modal from "../Modal/Modal";

interface NewJobProps {
  setSegment?: (segment: string) => void;
}

const NewJob: React.FC<NewJobProps> = ({ setSegment }) => {
  const loader = useLoading();
  const formikRef = useRef<FormikProps<Job>>(null);
  const jobTypeRef = useRef<CustomSelectRef>(null);
  const cycleRef = useRef<CustomSelectRef>(null);
  const sourceAutoCompleteRef = useRef<GoogleAutoCompleteRef>(null);
  const destinationAutoCompleteRef = useRef<GoogleAutoCompleteRef>(null);

  const [modalData, setModalData] = useState({
    source: "",
    sourceLatitude: "",
    sourceLongitude: "",
    destination: "",
    destLatitude: "",
    destLongitude: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const mapElement = document.getElementById("modal-map");
    if (!mapElement) {
      console.error("Map element not found! It is yet to be Triggered");
      return;
    }

    // Default map center: Delhi coordinates
    const defaultCenter = { lat: 28.6139, lng: 77.209 };

    // Map initialization with default center
    const mapOptions: google.maps.MapOptions = {
      center: defaultCenter,
      zoom: 8,
      streetViewControl: false,
      fullscreenControl: true,
      styles: [
        { elementType: "labels", stylers: [{ visibility: "off" }] },
        { featureType: "administrative", stylers: [{ visibility: "off" }] },
        { featureType: "poi", stylers: [{ visibility: "off" }] },
        {
          featureType: "road",
          elementType: "labels.icon",
          stylers: [{ visibility: "off" }],
        },
        { featureType: "transit", stylers: [{ visibility: "off" }] },
      ],
    };

    // Initialize the map
    const map = new google.maps.Map(mapElement as HTMLElement, mapOptions);

    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      polylineOptions: { strokeColor: "#027cb5", strokeWeight: 3 },
    });

    directionsRenderer.setMap(map);

    const geocoder = new google.maps.Geocoder();

    let sourceMarker: google.maps.Marker | null = null;
    let destinationMarker: google.maps.Marker | null = null;

    // Function to log place name and coordinates
    const logPlaceDetails = (position: google.maps.LatLng, label: string) => {
      geocoder.geocode({ location: position }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
          console.log(`${label} marker new position:`, {
            place: results[0].formatted_address,
            lat: position.lat(),
            lng: position.lng(),
          });
        } else {
          console.warn(`Unable to fetch place name for ${label} marker at`, {
            lat: position.lat(),
            lng: position.lng(),
          });
        }
      });
    };

    // Function to update the route
    const updateRoute = () => {
      if (sourceMarker && destinationMarker) {
        const sourcePosition = sourceMarker.getPosition();
        const destinationPosition = destinationMarker.getPosition();

        if (sourcePosition && destinationPosition) {
          directionsService.route(
            {
              origin: { lat: sourcePosition.lat(), lng: sourcePosition.lng() },
              destination: {
                lat: destinationPosition.lat(),
                lng: destinationPosition.lng(),
              },
              travelMode: google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
              if (status === google.maps.DirectionsStatus.OK) {
                directionsRenderer.setDirections(result);
              } else {
                console.error("Directions request failed due to " + status);
              }
            }
          );
        }
      }
    };

    const addDraggableMarker = (
      position: google.maps.LatLngLiteral,
      label: string,
      color: string
    ) => {
      const marker = new google.maps.Marker({
        position,
        map,
        draggable: false, // Make marker draggable
        label: { text: label, color: "#000", fontWeight: "400" },
        icon: {
          path: "M10.5 0.368164C4.97662 0.368164 0.5 4.84586 0.5 10.3682C0.5 13.4471 1.89154 16.1999 4.07974 18.0348L10.5 24.4562L16.9203 18.0359C19.1085 16.2011 20.5 13.4482 20.5 10.3692C20.5 4.84586 16.0231 0.368164 10.5 0.368164ZM10.5 17.0312C6.99332 17.0312 4.15067 14.1885 4.15067 10.6818C4.15067 7.17624 6.99332 4.33359 10.5 4.33359C14.0067 4.33359 16.8493 7.17624 16.8493 10.6829C16.8493 14.1885 14.0067 17.0312 10.5 17.0312Z",
          fillColor: color,
          fillOpacity: 1.0,
          strokeColor: "#000",
          strokeWeight: 1,
          scale: 1,
          anchor: new google.maps.Point(12, 24),
        },
      });

      // Add event listener for drag end
      marker.addListener("dragend", () => {
        const newPosition = marker.getPosition();
        if (newPosition) {
          logPlaceDetails(newPosition, label); // Log place name and coordinates
          updateRoute(); // Recalculate route when marker is moved
        }
      });

      return marker;
    };

    // Add source marker if coordinates are available
    if (modalData.sourceLatitude && modalData.sourceLongitude) {
      const sourcePosition = {
        lat: parseFloat(modalData.sourceLatitude),
        lng: parseFloat(modalData.sourceLongitude),
      };
      sourceMarker = addDraggableMarker(sourcePosition, "Source", "#cbd2d9");
      map.setCenter(sourcePosition); // Center map on the source
    }

    // Add destination marker if coordinates are available
    if (modalData.destLatitude && modalData.destLongitude) {
      const destinationPosition = {
        lat: parseFloat(modalData.destLatitude),
        lng: parseFloat(modalData.destLongitude),
      };
      destinationMarker = addDraggableMarker(
        destinationPosition,
        "Destination",
        "#19b5fe"
      );
      map.setCenter(destinationPosition); // Center map on the destination if source is not set
    }

    // Render the initial route
    updateRoute();
  }, [modalData]);

  const googleAutoCompleteRefSource = useRef<GoogleAutoCompleteRef>(null);
  const googleAutoCompleteRefDest = useRef<GoogleAutoCompleteRef>(null);
  const vesselRef = useRef<{
    resetVesselData: () => any;
  }>(null);
  const transporterRef = useRef<{
    generateTransporterJobHandlingRequests: () => any;
    resetData: () => any;
  }>(null);
  const { jobTypes, movementCycleTypes } = useMetadata();
  const [activeSection, setActiveSection] = useState("vessel");
  const [inventoryData, setInventoryData] = useState<Inventory[]>([]);
  const [inventoryError, setInventoryError] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false);
  const { triggerJobFetch } = useJobContext();

  const initialValues: Job = {
    jobCode: "",
    source: "",
    destination: "",
    sourceLatitude: "",
    sourceLongitude: "",
    destLatitude: "",
    destLongitude: "",
    jobDescription: "",
    jobType: "",
    service: "",
    vesselName: "",
    voyageVia: "",
    voa: "",
    cycle: "",
    gateOpenDate: "",
    gateCloseDate: "",
    transporterJobHandlingRequests: [],
  };

  const validationSchema = yup.object({
    jobCode: yup.string().required("Job Code is required"),
    source: yup.string().required("Source is required"),
    destination: yup.string().required("Destination is required"),
    cycle: yup.string().required("Cycle is required"),
    jobDescription: yup.string().required("Job Description is required"),
    jobType: yup.string().required("Job Type is required"),
    service: yup.string(),
    vesselName: yup.string().required("Vessel Name is required"),
    voyageVia: yup.string().required("Voyage Via is required"),
    voa: yup.string(),
    gateOpenDate: yup
      .number()
      .required("Gate Open Date & Time is required")
      .typeError("Gate Open Date must be a valid timestamp"),
    gateCloseDate: yup
      .number()
      .required("Gate Close Date & Time is required")
      .typeError("Gate Close Date must be a valid timestamp"),
    transporterJobHandlingRequests: yup
      .array()
      .of(
        yup.object().shape({
          transporterId: yup.number().required("Transporter ID is required"),
          countTypeSizeRequestList: yup.array().of(
            yup.object().shape({
              count: yup.number().required("Count is required"),
              containerType: yup
                .string()
                .required("Container Type is required"),
              containerSize: yup
                .string()
                .required("Container Size is required"),
            })
          ),
        })
      )
      .required("At least one request is required"),
  });

  const handleSetTouchedAndValidate = async () => {
    if (formikRef.current) {
      const allFieldsTouched = Object.keys(formikRef.current.values).reduce(
        (acc, key) => {
          acc[key] = true;
          return acc;
        },
        {} as { [key: string]: boolean }
      );
      await formikRef.current.setTouched(allFieldsTouched);
      await formikRef.current.validateForm();
    }
  };

  const handleNext = () => {
    handleSetTouchedAndValidate();
    if (inventoryData.length > 0) {
      handleSectionChange("transporter");
    } else {
      console.log("show error");
      setInventoryError(true);
    }
  };

  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  const getTransporterJobHandlingRequests = () => {
    if (transporterRef.current) {
      const transporterRequests =
        transporterRef.current.generateTransporterJobHandlingRequests();
      return transporterRequests;
    } else {
      return [];
    }
  };

  const handleSubmit = (values: Job) => {
    const transporterJobHandlingRequests = getTransporterJobHandlingRequests();
    const totalContainers = inventoryData.reduce(
      (sum, item) => sum + item.count,
      0
    );
    const totalAssignedContainers = transporterJobHandlingRequests.reduce(
      (total: number, transporter: TransporterJobHandlingRequest) => {
        const countFromCurrentTransporter =
          transporter.countTypeSizeRequestList.reduce((subTotal, request) => {
            return subTotal + request.count;
          }, 0);
        return total + countFromCurrentTransporter;
      },
      0
    );
    if (totalContainers === totalAssignedContainers) {
      values.transporterJobHandlingRequests = transporterJobHandlingRequests;
      const temp: any = JSON.parse(JSON.stringify(values));
      delete temp["jobId"]; // fix this issue from backend
      createJob(temp);
    } else {
      console.error("error !");
      showToast(`Please assign all containers before submitting !`, "error");
    }
  };

  const createJob = async (job: Job) => {
    loader.setLoading(true);
    try {
      const res = await JobService.createJob(job);
      if (res.data.error) {
        showToast(res.data.message, "error");
      } else {
        showToast("Job created successfully !", "success");
        resetJobForm();
        triggerJobFetch();
        if (setSegment) setSegment("Existing");
      }
      loader.setLoading(false);
    } catch (error) {
      console.log("Error creating job", error);
      loader.setLoading(false);

      showToast("Failed to create the job, please try again !", "error");
    }
  };

  const resetJobForm = () => {
    formikRef.current?.resetForm();
    jobTypeRef.current?.reset();
    cycleRef.current?.reset();
    googleAutoCompleteRefSource.current?.reset();
    googleAutoCompleteRefDest.current?.reset();
    vesselRef.current?.resetVesselData();
    transporterRef.current?.resetData();
    setActiveSection("vessel");
  };

  useEffect(() => {
    if (inventoryData.length > 0) {
      setInventoryError(false);
    }
  }, [inventoryData]);

  // const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openConfirmationModal = () => setIsConfirmationModalOpen(true);
  const closeConfirmationModal = () => setIsConfirmationModalOpen(false);

  // Open modal and pass current values
  const openModal = () => {
    if (formikRef.current) {
      const {
        source,
        sourceLatitude,
        sourceLongitude,
        destination,
        destLatitude,
        destLongitude,
      } = formikRef.current.values;
      setModalData({
        source,
        sourceLatitude,
        sourceLongitude,
        destination,
        destLatitude,
        destLongitude,
      });
    }
    setIsModalOpen(true);
  };

  // Update modal data as the user interacts with it
  const updateModalData = (key: string, value: string) => {
    setModalData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  console.log("Updated modalData:", modalData);

  // Sync input fields with modalData when modalData changes
  useEffect(() => {
    if (modalData.source === "") {
      sourceAutoCompleteRef.current?.reset();
    }
    if (modalData.destination === "") {
      destinationAutoCompleteRef.current?.reset();
    }
  }, [modalData.source, modalData.destination]);

  const handleModalSubmit = () => {
    console.log("Before Formik values:", formikRef.current?.values);

    if (formikRef.current) {
      formikRef.current.setValues((prevValues) => ({
        ...prevValues,
        source: modalData.source,
        sourceLatitude: modalData.sourceLatitude,
        sourceLongitude: modalData.sourceLongitude,
        destination: modalData.destination,
        destLatitude: modalData.destLatitude,
        destLongitude: modalData.destLongitude,
      }));
    }

    console.log("After Formik values:", formikRef.current?.values);

    closeModal();
  };

  const handleCloseModal = () => {
    console.log("Now Formik values:", formikRef.current?.values);

    setIsModalOpen(false);
  };

  return (
    <div className="new-job-page flex p-[20px]">
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isValid,
          dirty,
        }) => (
          <Form>
            <div className="form-cover">
              <div className="left-panel mr-[20px] ">
                <div className="bg-card rounded-[10px] card-shadow p-[16px]">
                  <div className="form-group">
                    <div className="form-field">
                      <label htmlFor="jobType" className="required_field_label">
                        Job Type
                      </label>
                      <CustomSelect
                        ref={jobTypeRef}
                        onBlurred={() => {
                          setFieldTouched("jobType", true);
                        }}
                        className={`h-[40px] ${
                          touched.jobType && errors.jobType
                            ? "invalid_field"
                            : "default_border"
                        }`}
                        options={jobTypes}
                        isMulti={false}
                        label="Job Type"
                        placeholder="Select Job Type"
                        onChange={(selected) => {
                          setFieldValue("jobType", selected);
                        }}
                      />
                    </div>
                    <ErrorMessage
                      name="jobType"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <div className="form-group">
                    <div className="form-field">
                      <label htmlFor="jobCode" className="required_field_label">
                        Job Code
                      </label>
                      <Field
                        type="text"
                        name="jobCode"
                        placeholder="Enter Job Code"
                        className={
                          touched.jobCode && errors.jobCode
                            ? "invalid_field"
                            : ""
                        }
                      />
                    </div>
                    <ErrorMessage
                      name="jobCode"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <div className="form-group">
                    <div className="form-field">
                      <div className="flex justify-between">
                        <label
                          htmlFor="source"
                          className="required_field_label"
                        >
                          Source
                        </label>
                        <button
                          type="button"
                          className="ml-[10px] text-blueText hover:underline text-[13px]"
                          onClick={openModal}
                        >
                          Choose from Map
                        </button>
                      </div>
                      <GoogleAutoComplete
                        ref={googleAutoCompleteRefSource}
                        onBlurred={() => {
                          setFieldTouched("source", true);
                          setFieldTouched("sourceLatitude", true);
                          setFieldTouched("sourceLongitude", true);
                          // console.log("onBlurred");
                          // console.log(errors);
                        }}
                        className={
                          touched.source && errors.source ? "invalid_field" : ""
                        }
                        placeholder={"Enter Source"}
                        valueloc={formikRef.current?.values.source}
                        onPlaceSelected={(
                          place: google.maps.places.PlaceResult
                        ) => {
                          const address = place.name || "";
                          const lat = place.geometry?.location?.lat() || null;
                          const lng = place.geometry?.location?.lng() || null;
                          setFieldValue("source", address);
                          setFieldValue("sourceLatitude", lat?.toString());
                          setFieldValue("sourceLongitude", lng?.toString());
                          setTimeout(() => {
                            setFieldTouched("sourceLatitude", false);
                            setFieldTouched("sourceLongitude", false);
                          });
                        }}
                        onCleared={() => {
                          setFieldValue("source", "");
                          setFieldValue("sourceLatitude", "");
                          setFieldValue("sourceLongitude", "");
                          setFieldTouched("sourceLatitude", true);
                          setFieldTouched("sourceLongitude", true);
                          // console.log("onCleared");
                        }}
                      ></GoogleAutoComplete>
                    </div>
                    <ErrorMessage
                      name="source"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <div className="form-group">
                    <div className="form-field">
                      <div className="flex justify-between">
                        <label
                          htmlFor="destination"
                          className="required_field_label"
                        >
                          Destination
                        </label>
                        <button
                          type="button"
                          className="ml-[10px] text-blueText hover:underline text-[13px]"
                          onClick={openModal}
                        >
                          Choose from Map
                        </button>
                      </div>

                      <GoogleAutoComplete
                        ref={googleAutoCompleteRefDest}
                        onBlurred={() => {
                          setFieldTouched("destination", true);
                          setFieldTouched("destLatitude", true);
                          setFieldTouched("destLongitude", true);
                          // console.log(errors);
                        }}
                        className={
                          touched.destination && errors.destination
                            ? "invalid_field"
                            : ""
                        }
                        placeholder={"Enter Destination"}
                        valueloc={formikRef.current?.values.destination}
                        onPlaceSelected={(
                          place: google.maps.places.PlaceResult
                        ) => {
                          const address = place.name || "";
                          const lat = place.geometry?.location?.lat() || null;
                          const lng = place.geometry?.location?.lng() || null;
                          setFieldValue("destination", address);
                          setFieldValue("destLatitude", lat?.toString());
                          setFieldValue("destLongitude", lng?.toString());
                          setTimeout(() => {
                            setFieldTouched("destLatitude", false);
                            setFieldTouched("destLongitude", false);
                          });
                        }}
                        onCleared={() => {
                          setFieldValue("destination", "");
                          setFieldValue("destLatitude", "");
                          setFieldValue("destLongitude", "");
                          setFieldTouched("destLatitude", true);
                          setFieldTouched("destLongitude", true);
                        }}
                      ></GoogleAutoComplete>
                    </div>
                    <ErrorMessage
                      name="destination"
                      component="div"
                      className="validation_error"
                    />
                  </div>

                  <div className="form-group no-mb">
                    <div className="form-field">
                      <label htmlFor="cycle" className="required_field_label">
                        Cycle
                      </label>
                      <CustomSelect
                        ref={cycleRef}
                        onBlurred={() => {
                          setFieldTouched("cycle", true);
                        }}
                        className={`h-[40px] ${
                          touched.cycle && errors.cycle
                            ? "invalid_field"
                            : "default_border"
                        }`}
                        options={movementCycleTypes}
                        isMulti={false}
                        label="Movement Cycle"
                        placeholder="Select Movement Cycle"
                        onChange={(selected) => {
                          setFieldValue("cycle", selected);
                        }}
                      />
                    </div>
                    <ErrorMessage
                      name="cycle"
                      component="div"
                      className="validation_error"
                    />
                  </div>
                </div>

                {activeSection === "transporter" && (
                  <div className="bg-card mt-[20px] rounded-[10px] card-shadow overflow-hidden">
                    <Preview inventoryData={inventoryData} />
                  </div>
                )}
              </div>

              <div className="right-panel bg-white rounded-[10px] card-shadow">
                <div className="flex flex-start	border-b border-bottomline m-b-[20px]">
                  <button
                    type="button"
                    className={`min-w-[100px] px-4 mx-[20px] min-h-[60px] font-w-[500] border-b-[1px] ${
                      activeSection === "vessel"
                        ? "border-blueText text-blueText"
                        : "text-notSelected  border-b-transparent"
                    }`}
                    onClick={() => handleSectionChange("vessel")}
                  >
                    Vessel & Inventory Details
                  </button>
                  <button
                    type="button"
                    className={`min-w-[100px] px-4 mx-[20px] min-h-[60px] font-w-[500]  border-b-[1px]  ${
                      activeSection === "transporter"
                        ? "border-blueText text-blueText"
                        : "text-notSelected border-b-transparent"
                    }`}
                    onClick={() => handleSectionChange("transporter")}
                  >
                    Transporter
                  </button>
                </div>
                <div
                  className={activeSection === "vessel" ? "visible" : "hidden"}
                >
                  <Vessel
                    ref={vesselRef}
                    setInventoryData={setInventoryData}
                    inventoryError={inventoryError}
                  />
                </div>
                <div
                  className={
                    activeSection === "transporter" ? "visible" : "hidden"
                  }
                >
                  <Transporter
                    inventoryData={inventoryData}
                    ref={transporterRef}
                  />
                </div>

                <div className="flex justify-center mt-2 gap-[10px] action-button-bar">
                  {/* <button
                    type="button"
                    onClick={() => console.log("Draft ?")}
                    className="secondary_button min-w-[160px]"
                  >
                    Save as Draft
                  </button> */}

                  <button
                    type="button"
                    onClick={handleNext}
                    className={
                      "secondary_button min-w-[160px] " +
                      (activeSection === "vessel" ? "visible" : "hidden")
                    }
                  >
                    Next
                  </button>

                  <button
                    type="submit"
                    className={
                      "primary_button min-w-[160px] " +
                      (activeSection === "transporter" ? "visible" : "hidden")
                    }
                    disabled={!isValid}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <Modal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              title="Select Source & Destination"
            >
              <div className="space-y-6">
                <div className="flex gap-6">
                  {/* Source Input */}
                  <div className="form-group w-1/2">
                    <label className="block text-sm font-medium text-gray-700 mb-2 required_field_label">
                      Source
                    </label>
                    <GoogleAutoComplete
                      ref={sourceAutoCompleteRef}
                      onBlurred={() => {
                        setFieldTouched("source", true);
                      }}
                      className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${
                        touched.source && errors.source
                          ? "border-red-500 focus:ring-red-400"
                          : "border-gray-300 focus:ring-blue-400"
                      }`}
                      placeholder="Enter Source"
                      valueloc={formikRef.current?.values.source}
                      onPlaceSelected={(
                        place: google.maps.places.PlaceResult
                      ) => {
                        const address = place.name || "";
                        const lat = place.geometry?.location?.lat() || null;
                        const lng = place.geometry?.location?.lng() || null;
                        updateModalData("source", place.name || "");
                        updateModalData(
                          "sourceLatitude",
                          place.geometry?.location?.lat()?.toString() || ""
                        );
                        updateModalData(
                          "sourceLongitude",
                          place.geometry?.location?.lng()?.toString() || ""
                        );
                        setFieldValue("source", address);
                        setFieldValue("sourceLatitude", lat?.toString());
                        setFieldValue("sourceLongitude", lng?.toString());
                      }}
                      onCleared={() => {
                        setFieldValue("source", "");
                        setFieldValue("sourceLatitude", "");
                        setFieldValue("sourceLongitude", "");
                      }}
                    />
                    {touched.source && errors.source && (
                      <p className="mt-2 text-sm text-red-600">
                        {errors.source}
                      </p>
                    )}
                  </div>

                  {/* Destination Input */}
                  <div className="form-group  w-1/2">
                    <label className="block text-sm font-medium text-gray-700 mb-2 required_field_label">
                      Destination
                    </label>
                    <GoogleAutoComplete
                      ref={destinationAutoCompleteRef}
                      onBlurred={() => {
                        setFieldTouched("destination", true);
                      }}
                      className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${
                        touched.destination && errors.destination
                          ? "border-red-500 focus:ring-red-400"
                          : "border-gray-300 focus:ring-blue-400"
                      }`}
                      placeholder="Enter Destination"
                      valueloc={formikRef.current?.values.destination}
                      onPlaceSelected={(
                        place: google.maps.places.PlaceResult
                      ) => {
                        const address = place.name || "";
                        const lat = place.geometry?.location?.lat() || null;
                        const lng = place.geometry?.location?.lng() || null;
                        setFieldValue("destination", address);
                        setFieldValue("destLatitude", lat?.toString());
                        setFieldValue("destLongitude", lng?.toString());
                        updateModalData("destination", place.name || "");
                        updateModalData(
                          "destLatitude",
                          place.geometry?.location?.lat()?.toString() || ""
                        );
                        updateModalData(
                          "destLongitude",
                          place.geometry?.location?.lng()?.toString() || ""
                        );
                      }}
                      onCleared={() => {
                        setFieldValue("destination", "");
                        setFieldValue("destLatitude", "");
                        setFieldValue("destLongitude", "");
                      }}
                    />
                    {touched.destination && errors.destination && (
                      <p className="mt-2 text-sm text-red-600">
                        {errors.destination}
                      </p>
                    )}
                  </div>
                </div>

                {/* Map Container */}
                <div
                  id="modal-map"
                  className="w-full rounded-lg border border-gray-300"
                ></div>

                <div className="w-full flex justify-center mt-[20px] gap-[20px]">
                  <button
                    type="button"
                    onClick={handleCloseModal}
                    className="secondary_button w-[128px]"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={handleModalSubmit}
                    className="primary_button  w-[128px]"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Modal>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewJob;
