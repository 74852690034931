import "./ExistingJob.scss";
import Modal from "../Modal/Modal";
import { useEffect, useState, useCallback, useRef } from "react";
import JobCard from "./JobCard";
import JobDetails from "./JobDetails";
import TransporterDetails from "../TransporterDetails";
import JobService from "../../services/JobService";
import { JobOrder } from "../../interfaces/job.interface";
import { useJobContext } from "../../contexts/JobContext";
import JobBreakupTable from "./JobBreakupTable";
import { useLoading } from "../../contexts/LoadingContext";
import ContainerDetails from "./ContainerDetails";
import JobStatus from "./JobStatus";
import noJobInfoGraphics from "./../../assets/images/no-data-infographics.svg";
import { useLoader } from "../../contexts/LoaderContext";
import { generateExcelSheet } from "../../utils/generateExcelSheet";
import { showToast } from "../Toaster";
import { handleExcelFileUpload } from "../../utils/importContainerDataFromExcel";
import { Container } from "../../interfaces/container.interface";

interface ExistingJobProps {
  currentSegment?: string;
  status?: string;
}

const ExistingJob: React.FC<ExistingJobProps> = ({
  currentSegment,
  status,
}) => {
  const [jobs, setJobs] = useState<JobOrder[]>([]);
  const [selectedJob, setSelectedJob] = useState<JobOrder>();
  const [view, setView] = useState("jobDetails");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isImportContainerModalOpen, setIsImportContainerModalOpen] =
    useState<boolean>(false);
  const { shouldFetchJobs, resetJobFetch } = useJobContext();
  const [hasMore, setHasMore] = useState(true);
  const isLoading = useRef(false);
  const page = useRef(0);
  const loader = useLoading();
  const { showLoader, hideLoader } = useLoader();
  const refreshInterval = 10 * 1000; // 10 seconds
  const [uploadedData, setUploadedData] = useState<Container[]>([]);
  const [validUploadedData, setValidUploadedData] = useState<Container[]>([]);
  const [uploadedJobCode, setUploadedJobCode] = useState("");

  const refreshSelectedJob = useCallback(async () => {
    if (
      currentSegment !== "Existing" ||
      !selectedJob?.jobId ||
      selectedJob?.jobStatus === "COMPLETED"
    )
      return;
    try {
      const updatedJobRes = await JobService.getJobDetails(
        parseInt(selectedJob.jobId)
      );
      const updatedJob = updatedJobRes.data.data;
      if (!updatedJob) return;
      const isJobUpdated =
        JSON.stringify(selectedJob) !== JSON.stringify(updatedJob);
      if (isJobUpdated) {
        setSelectedJob(updatedJob);
        setJobs((prevJobs) =>
          prevJobs.map((job) =>
            job.jobId === updatedJob.jobId ? updatedJob : job
          )
        );
      }
    } catch (error) {
      console.error("Failed to refresh selected job", error);
    }
  }, [selectedJob, currentSegment]);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshSelectedJob();
    }, refreshInterval);

    return () => clearInterval(interval);
  }, [refreshSelectedJob]);

  const sanitizeJobs = (jobs: JobOrder[]) => {
    return jobs.map(({ links, ...rest }) => rest);
  };

  const loadJobs = useCallback(async () => {
    isLoading.current = true;
    if (page.current === 0 && currentSegment === "Existing") showLoader();
    if (page.current > 0) loader.setLoading(true);
    try {
      const res = await JobService.getMyJobs({
        limit: 10,
        skip: page.current,
      });
      const newJobs = sanitizeJobs(res.data.data.content);
      if (newJobs.length > 0) {
        setJobs((prevJobs) => [
          ...prevJobs,
          ...newJobs.filter(
            (job: JobOrder) => !prevJobs.some((j) => j.jobCode === job.jobCode)
          ),
        ]);
      }
      if (page.current === 0) {
        handleSelectJob(newJobs[0]);
      }
      if (page.current < res.data.data.page.totalPages - 1) {
        setHasMore(true);
        page.current += 1; // Increment the page for the next load
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error loading jobs", error);
    } finally {
      isLoading.current = false;
      setTimeout(() => {
        loader.setLoading(false);
        hideLoader();
      }, 1000);
    }
  }, [hasMore, selectedJob]);

  useEffect(() => {
    loadJobs();
  }, []);

  useEffect(() => {
    if (shouldFetchJobs) {
      setJobs([]);
      page.current = 0;
      setHasMore(true);
      loadJobs(); // Initial load after reset
      resetJobFetch();
    }
  }, [shouldFetchJobs, resetJobFetch, loadJobs]);

  const handleScroll = useCallback(() => {
    const scrollContainer = document.querySelector("#jobs-list");
    if (scrollContainer) {
      const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
      if (
        scrollHeight - scrollTop <= clientHeight * 1.5 &&
        hasMore &&
        !isLoading.current
      ) {
        loadJobs();
      }
    }
  }, [hasMore, loadJobs]);

  useEffect(() => {
    const scrollContainer = document.querySelector("#jobs-list");
    scrollContainer?.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleSelectJob = (job: JobOrder) => {
    setSelectedJob(job);
  };

  const handleViewChange = (view: string) => {
    setView(view);
  };

  const handleUpdatedJob = (updatedJob: JobOrder) => {
    if (!updatedJob || !updatedJob.jobId) {
      console.error("Invalid updated job:", updatedJob);
      return;
    }
    setJobs((prevJobs) =>
      prevJobs.map((job) => (job.jobId === updatedJob.jobId ? updatedJob : job))
    );
    setSelectedJob(updatedJob);
  };

  const downloadExcelTemplate = async () => {
    const headers = ["Job Code", "Container Number", "Size", "Type"];
    const data = [
      {
        "Job Code": "#" + selectedJob?.jobCode,
      },
    ];
    console.log(selectedJob);
    loader.setLoading(true);
    try {
      await generateExcelSheet(headers, data, {
        fileName: `${"#" + selectedJob?.jobCode}.xlsx`,
        download: true,
      });
      showToast(`Downloaded ${"#" + selectedJob?.jobCode}.xlsx`, "success");
      setTimeout(() => {
        loader.setLoading(false);
      }, 1000);
    } catch (error) {
      showToast(`Download ${"#" + selectedJob?.jobCode}.xlsx failed`, "error");
      setTimeout(() => {
        loader.setLoading(false);
      }, 1000);
    }
  };

  const readSelectedExcel = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;
    await handleExcelFileUpload(
      file,
      ({ jobCode, data }) => {
        setUploadedJobCode(jobCode);
        setUploadedData(data);
      },
      (error) => {
        showToast(error, "error");
      }
    );
  };

  const handleOpenImportContainerDataModal = () => {
    setIsImportContainerModalOpen(true);
  };

  const handleCloseImportContainerDataModal = () => {
    setIsImportContainerModalOpen(false);
    setUploadedData([]);
    setValidUploadedData([]);
    setUploadedJobCode("");
  };

  const customHeaderButtons = (
    <>
      <button
        className="excel_download_button"
        type="button"
        onClick={() => {
          downloadExcelTemplate();
        }}
        disabled={loader.isLoading}
      >
        Download Excel File
      </button>

      <button
        className="excel_import_button"
        type="button"
        onClick={handleOpenImportContainerDataModal}
      >
        Import from Excel File
      </button>
    </>
  );

  const isIntegrateButtonEnabled = () => {
    return (
      uploadedJobCode.trim() !== "" &&
      uploadedJobCode ===
        selectedJob?.jobCode.trim().replace(/#/g, "").toUpperCase()
    );
  };

  const integrateUploadedData = () => {
    loader.setLoading(true);
    setValidUploadedData(uploadedData);
    setTimeout(() => {
      handleCloseImportContainerDataModal();
      loader.setLoading(false);
    });
  };

  return (
    <div className="flex existing-job-page">
      {!isLoading.current && jobs.length === 0 && (
        <div className="center_floater_container">
          <div className="no_data_wrapper pr-[126px]">
            <img
              className="info_graphics"
              src={noJobInfoGraphics}
              alt="No Jobs"
            />
            <div className="msg">
              There are no jobs available at the moment !
            </div>
          </div>
        </div>
      )}
      {jobs.length > 0 && (
        <>
          <div className="p-[20px] jobs-list" id="jobs-list">
            <JobCard
              CardData={jobs}
              selectedJobId={selectedJob?.jobCode}
              onSelect={handleSelectJob}
            />
          </div>
          <div className="bg-card  my-[20px] mr-[20px]  rounded-[10px] selected-job-details card-shadow">
            {!selectedJob && (
              <div className="center_floater_container">
                <div className="no_data_wrapper">
                  <img
                    className="info_graphics"
                    src={noJobInfoGraphics}
                    alt="No Jobs"
                  />
                  <div className="msg">Select a job to view the details !</div>
                </div>
              </div>
            )}
            {selectedJob && (
              <div className="details_panel">
                <div className="flex justify-between items-center p-[20px] pb-1">
                  <div className="text-[18px] text-gray-900">
                    #{selectedJob.jobCode}
                  </div>
                  <JobStatus jobStatus={selectedJob.jobStatus} />
                </div>
                <div className="flex px-[20px] border-b border-bottomline justify-between min-h-[47px]">
                  <div className="flex">
                    <button
                      className={`mr-2 py-2 px-4 font-w-[500] ${
                        view === "jobDetails"
                          ? "border-b border-blueText text-blueText"
                          : "text-notSelected"
                      }`}
                      onClick={() => handleViewChange("jobDetails")}
                    >
                      Job Details
                    </button>
                    <button
                      className={`py-2 px-4 font-w-[500] ${
                        view === "containerDetails"
                          ? "border-b border-blueText text-blueText"
                          : "text-notSelected"
                      }`}
                      onClick={() => handleViewChange("containerDetails")}
                    >
                      Containers
                    </button>
                    <button
                      className={`py-2 px-4 font-w-[500] ${
                        view === "transporterDetails"
                          ? "border-b border-blueText text-blueText"
                          : "text-notSelected"
                      }`}
                      onClick={() => handleViewChange("transporterDetails")}
                    >
                      Transporters
                    </button>
                  </div>
                </div>

                {view === "jobDetails" && <JobDetails job={selectedJob} />}

                <div
                  className={view === "containerDetails" ? "visible" : "hidden"}
                >
                  <ContainerDetails
                    job={selectedJob}
                    onAssignRequest={() => setIsModalOpen(true)}
                  ></ContainerDetails>
                </div>

                <div
                  className={
                    view === "transporterDetails" ? "visible" : "hidden"
                  }
                >
                  <TransporterDetails jobOrder={selectedJob} />
                </div>
              </div>
            )}
          </div>
          <Modal
            className="component_modal_container assign_containers_modal"
            title="Assign Containers"
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            headerButtons={customHeaderButtons}
          >
            {selectedJob && (
              <JobBreakupTable
                job={selectedJob}
                onSave={(updatedJob) => handleUpdatedJob(updatedJob)}
                onCancel={() => setIsModalOpen(false)}
                excelImportedData={validUploadedData}
              ></JobBreakupTable>
            )}
          </Modal>

          <Modal
            isOpen={isImportContainerModalOpen}
            onClose={handleCloseImportContainerDataModal}
            title={`Import via Excel for #${selectedJob?.jobCode}`}
            className="component_modal_container import_containers_modal"
          >
            <div className="import_excel_component">
              <div className="upload_area">
                <input
                  type="file"
                  onChange={readSelectedExcel}
                  accept=".xlsx, .xls, .csv"
                />
              </div>
              {uploadedJobCode && (
                <div className="flex justify-center p-[10px]">
                  <p
                    className={`text-sm font-medium ${
                      uploadedJobCode ===
                      selectedJob?.jobCode
                        .trim()
                        .replace(/#/g, "")
                        .toUpperCase()
                        ? "text-green-600"
                        : "text-red-500"
                    }`}
                  >
                    {uploadedJobCode ===
                    selectedJob?.jobCode.trim().replace(/#/g, "").toUpperCase()
                      ? "✔ "
                      : "✘ "}
                    #{selectedJob?.jobCode}
                  </p>
                </div>
              )}
              {uploadedData.length > 0 ? (
                <div className="overflow-auto max-h-80 border rounded-lg shadow-sm">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 sticky top-0 z-10">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                        >
                          Sl. No.
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                        >
                          Container Number
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                        >
                          Size
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                        >
                          Type
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {uploadedData.map((data: any, index: any) => (
                        <tr key={index}>
                          <td className="px-6 py-4 text-sm text-gray-700 text-center">
                            {index + 1}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-700">
                            {data.containerNumber}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-700">
                            {data.containerSize}
                          </td>
                          <td className="px-6 py-4 text-sm text-gray-700">
                            {data.containerType}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p className="text-[11px] text-gray-400 mt-2">
                  Please upload an Excel file (.xlsx) to import container data
                </p>
              )}
              <div className="w-full flex justify-center mt-[20px] gap-[20px]">
                <button
                  type="button"
                  onClick={handleCloseImportContainerDataModal}
                  className="secondary_button w-[128px]"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={integrateUploadedData}
                  disabled={!isIntegrateButtonEnabled()}
                  className="primary_button  w-[128px]"
                >
                  Import
                </button>
              </div>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default ExistingJob;
