import "./JobDetails.scss";
import React from "react";
import { JobBreakup, JobOrder } from "../../interfaces/job.interface";
import {
  calculateCycleTime,
  containerSizeStringToNumber,
  formatEpochDate,
} from "../../utils/general";
import MapComponent from "../MapComponent/MapComponent";

interface JobDetailsProps {
  job: JobOrder;
}

const JobDetails: React.FC<JobDetailsProps> = ({ job }) => {
  return (
    <div className="job-details-page p-[20px] bg-white text-left overflow-y-auto">
      <div className="details_holder">
        <div className="row">
          <div className="label">Source</div>
          <div className="value truncate-one-line" title={job.source}>
            {job.source}
          </div>
        </div>
        <div className="row">
          <div className="label">Destination</div>
          <div className="value truncate-one-line" title={job.destination}>
            {job.destination}
          </div>
        </div>
        <div className="row">
          <div className="label">Job Type</div>
          <div className="value">{job.jobType}</div>
        </div>
        <div className="row">
          <div className="label">Movement</div>
          <div className="value">{job.cycle}</div>
        </div>
        <div className="row">
          <div className="label">Vessel Name</div>
          <div className="value">{job.vesselName || "N/A"}</div>
        </div>
        <div className="row">
          <div className="label">Service</div>
          <div className="value">{job.service || "N/A"}</div>
        </div>
        <div className="row">
          <div className="label">VOA</div>
          <div className="value">{job.voa || "N/A"}</div>
        </div>
        <div className="row">
          <div className="label">VOA / VIA</div>
          <div className="value">{job.voyageVia || "N/A"}</div>
        </div>
        <div className="row">
          <div className="label">Gate Open</div>
          <div className="value">{formatEpochDate(job.gateOpenDate)}</div>
        </div>
        <div className="row">
          <div className="label">Cut Off</div>
          <div className="value">{formatEpochDate(job.gateCloseDate)}</div>
        </div>
        <div className="row">
          <div className="label">Total</div>
          <div className="value">
            {job.jobBreakups.reduce(
              (total, breakup) => total + breakup.count,
              0
            )}{" "}
            Container (s)
          </div>
        </div>
        <div className="row">
          <div className="label">Cycle Time</div>
          <div className="value">
            {calculateCycleTime(job.gateOpenDate, job.gateCloseDate)}
          </div>
        </div>
      </div>

      <div className="inventory_details">
        <div className="label">Inventory</div>
        <div className="value">
          {job.jobBreakups.map((inventoryItem: JobBreakup, index) => (
            <div key={index} className="text-center">
              <span>
                {inventoryItem.count} x{" "}
                {containerSizeStringToNumber(inventoryItem.containerSize)}{" "}
                {inventoryItem.containerType}
              </span>
            </div>
          ))}
        </div>
      </div>

      <div className="trip_estimations">
        <div className="section">
          <div className="left">
            <div className="label">Distance</div>
            <div className="value">
              {job.tripEstimate.distanceInMts / 1000} Kms
            </div>
          </div>
        </div>
        <div className="section">
          <div className="right">
            <div className="label">Estimated Time</div>
            <div className="value">
              {job.tripEstimate.durationWithTrafficString}
            </div>
          </div>
        </div>
      </div>

      <MapComponent job={job} />
    </div>
  );
};

export default JobDetails;
