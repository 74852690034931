import { api } from "../AxiosInstance";
import { Job, TripContainer } from "../interfaces/job.interface";
import { urlQueryBuilder } from "../utils/urlQueryBuilder";

const JobService = {
  getMyJobs: async (
    queryParams: {
      status?: string;
      skip?: number;
      limit?: number;
    } = {}
  ) => {
    try {
      const queryString = urlQueryBuilder(queryParams);
      const response = await api.get(
        `/job${queryString ? `?${queryString}` : ""}`
      );
      return response;
      // return response.data; // Return only the data payload
    } catch (error) {
      console.error("Error getMyJobs:", error);
      throw error; // Re-throw error for global handling if necessary
    }
  },

  createJob: async (job: Job) => {
    try {
      const response = await api.post("/job", job);
      return response;
    } catch (error) {
      console.error("Error createJob:", error);
      throw error; // Re-throw error for global handling if necessary
    }
  },

  updateJob: () => {
    console.log("update job");
    try {
      // return response.data; // Return only the data payload
    } catch (error) {
      console.error("Error updateJob:", error);
      throw error;
    }
  },

  assignContainers: async (containersList: TripContainer[]) => {
    try {
      const response = await api.put(`/job/edit-trip`, containersList);
      return response;
    } catch (error) {
      console.error("Error assignContainers:", error);
      throw error;
    }
  },

  getJobDetails: async (jobId: number) => {
    try {
      const response = await api.get("/job/jobId?jobId=" + jobId);
      return response;
    } catch (error) {
      console.error("Error getJobDetails:", error);
      throw error;
    }
  },

  trackDriver: async (driverId: number) => {
    try {
      const response = await api.get(
        "/job/driver-location?driverId=" + driverId
      );
      return response;
    } catch (error) {
      console.error("Error trackDriver:", error);
      throw error;
    }
  },

  getJobHistory: async (
    queryParams: {
      status?: string;
      skip?: number;
      limit?: number;
      jobCode?: string;
      from?: number | null;
      to?: number | null;
    } = {}
  ) => {
    try {
      const queryString = urlQueryBuilder(queryParams);
      const response = await api.get(
        `/job-history${queryString ? `?${queryString}` : ""}`
      );
      return response;
      // return response.data; // Return only the data payload
    } catch (error) {
      console.error("Error getJobHistory:", error);
      throw error; // Re-throw error for global handling if necessary
    }
  },

  getJobHistoryStats: async () => {
    try {
      const response = await api.get("/job-history/trip-stats");
      return response.data;
    } catch (error) {
      console.error("Error getJobHistoryStats:", error);
      throw error;
    }
  },
};

export default JobService;
