import "./ContainerDetails.scss";
import React, { useEffect, useState } from "react";
import { JobBreakUpDetail, JobOrder } from "../../interfaces/job.interface";
import {
  containerSizeStringToNumber,
  formatIsoDate,
  getContainerStatus,
  transformTruckNumber,
} from "../../utils/general";
import Modal from "../Modal/Modal";
import { ReactComponent as LocationIcon } from "../../assets/icons/location-pin-icon.svg";
import { ReactComponent as StatusIcon } from "../../assets/icons/job-status-icon.svg";
import { ReactComponent as LocationTickIcon } from "../../assets/icons/location-pin-with-tick.svg";
import noJobInfoGraphics from "./../../assets/images/no-data-infographics.svg";
import LiveTracking from "../LiveTracking/LiveTracking";

interface ContainerDetailsProps {
  job: JobOrder;
  hideContainerAssignment?: boolean;
  onAssignRequest?: () => void;
}

const ContainerDetails: React.FC<ContainerDetailsProps> = ({
  job,
  hideContainerAssignment = false,
  onAssignRequest,
}) => {
  const [stats, setStats] = useState({
    totalContainers: 0,
    totalAssigned: 0,
    pendingToAssign: 0,
    assignmentByTransporters: new Map<string, number>(), // Map to store transporter name and assigned count
  });

  const [selectedJobBreakupDetailId, setSelectedJobBreakUpDetailsId] =
    useState<number>();

  const [isTrackingModalOpen, setIsTrackingModalOpen] =
    useState<boolean>(false);

  const showTracking = (containerDetail: JobBreakUpDetail) => {
    console.log(containerDetail);
    setSelectedJobBreakUpDetailsId(containerDetail.jobBreakupDetailId);
    setIsTrackingModalOpen(true);
  };

  const hideTracking = () => {
    setIsTrackingModalOpen(false);
  };

  useEffect(() => {
    if (job) {
      const assignmentByTransporters = new Map<string, number>();
      let pendingToAssign = 0;
      let totalAssigned = 0;

      const totalContainers = job.jobBreakups.reduce(
        (total, breakup) => total + breakup.count,
        0
      );

      job.jobBreakups.forEach((breakup) => {
        breakup.jobBreakUpDetails.forEach((container: JobBreakUpDetail) => {
          if (container.containerNumber) {
            totalAssigned++;
            assignmentByTransporters.set(
              container.transporterIdName.name,
              (assignmentByTransporters.get(container.containerNumber) || 0) + 1
            );
          } else {
            pendingToAssign++;
          }
        });
      });

      setStats({
        totalContainers,
        totalAssigned,
        pendingToAssign,
        assignmentByTransporters,
      });
    }
  }, [job]);

  return (
    <div className="container-details-component">
      <div className="stats">
        <div className="stat-block w-1/3">
          <div className="label">Total Containers</div>
          <div className="value total_value">{stats.totalContainers}</div>
        </div>
        <div className="stat-block  w-1/3">
          <div className="label">Assigned Containers</div>
          <div className="value assigned_value">{stats.totalAssigned}</div>
        </div>
        <div className="stat-block  w-1/3">
          <div className="label">Pending Assignments</div>
          <div className="value pending_value">{stats.pendingToAssign}</div>
        </div>
      </div>

      {(stats.totalAssigned === 0 || stats.pendingToAssign > 0) && (
        <>
          {/* {stats.totalAssigned === 0 && (
            <div className="no_container_data center_floater_container">
              <div className="no_data_wrapper">
                <img
                  className="info_graphics  max-w-[400px]"
                  src={noJobInfoGraphics}
                  alt="No Jobs"
                />
                <div className="msg  max-w-[400px]">
                  Please provide container numbers to initiate pick up
                </div>
              </div>
            </div>
          )} */}

          {!hideContainerAssignment && stats.pendingToAssign > 0 && (
            <div
              className={`flex justify-center ${
                stats.totalAssigned > 0 ? "mb-[20px]" : ""
              }`}
            >
              <button
                type="button"
                className="text-black rounded-[5px] border border-black h-[40px] w-[180px]"
                onClick={onAssignRequest}
              >
                Assign Containers
              </button>
            </div>
          )}
        </>
      )}

      {stats.totalAssigned > 0 && (
        <>
          <div className="table-container">
            <table className="containers-table">
              <thead>
                <tr>
                  <th className="sl_no">Sl No.</th>
                  <th className="container_no">Container Number</th>
                  <th className="sity">Size & Type</th>

                  <th className="truckNo">Truck Number</th>
                  {/* <th className="status">Status</th> */}
                  <th className="datetime">Pick & Drop Date</th>
                  <th className="track">Live Tracking</th>
                </tr>
              </thead>
              <tbody>
                {job.jobBreakups.flatMap((breakup, index) => {
                  const previousCount = job.jobBreakups
                    .slice(0, index) // Only consider breakups before the current one
                    .reduce(
                      (acc, prevBreakup) =>
                        acc +
                        prevBreakup.jobBreakUpDetails.filter(
                          (detail) => detail.containerNumber
                        ).length,
                      0
                    );
                  return breakup.jobBreakUpDetails
                    .filter(
                      (containerDetail) => containerDetail.containerNumber
                    )
                    .map((containerDetail, detailIndex) => (
                      <tr key={previousCount + detailIndex + 1}>
                        <td className="sl_no">
                          {" "}
                          {previousCount + detailIndex + 1}
                        </td>
                        <td>{containerDetail.containerNumber}</td>
                        <td>
                          {containerSizeStringToNumber(breakup.containerSize)}{" "}
                          {breakup.containerType}
                        </td>
                        <td>
                          {containerDetail.truckIdName
                            ? transformTruckNumber(
                                containerDetail.truckIdName.name
                              )
                            : "N/A"}
                        </td>
                        <td>
                          <div>
                            {containerDetail.startDate
                              ? formatIsoDate(containerDetail.startDate)
                              : "N/A"}
                          </div>
                          <div>
                            {containerDetail.endDate
                              ? formatIsoDate(containerDetail.endDate)
                              : "N/A"}
                          </div>
                        </td>
                        <td>
                          {containerDetail.truckIdName ? (
                            <>
                              {getContainerStatus(containerDetail) ===
                                "Picked" && (
                                <button
                                  className="track_button w-full picked_status"
                                  onClick={() => {
                                    showTracking(containerDetail);
                                  }}
                                >
                                  <div className="button_inner_container">
                                    <LocationIcon />
                                    <span className="truckNo">
                                      {getContainerStatus(containerDetail)}
                                    </span>
                                  </div>
                                </button>
                              )}
                              {getContainerStatus(containerDetail) ===
                                "Dropped" && (
                                <button
                                  className="track_button w-full dropped_status"
                                  disabled
                                >
                                  <div className="button_inner_container">
                                    <LocationTickIcon />
                                    <span className="truckNo">
                                      {getContainerStatus(containerDetail)}
                                    </span>
                                  </div>
                                </button>
                              )}
                            </>
                          ) : (
                            <>
                              <button
                                className="track_button w-full"
                                disabled={true}
                              >
                                <div className="button_inner_container">
                                  <StatusIcon></StatusIcon>
                                  <span className="truckNo"> Not Started</span>
                                </div>
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    ));
                })}
              </tbody>
            </table>
          </div>
          {selectedJobBreakupDetailId !== undefined && (
            <Modal
              className="live-tracking-modal"
              title="Live Tracking"
              isOpen={isTrackingModalOpen}
              onClose={() => hideTracking()}
            >
              <LiveTracking
                jobBreakupDetailId={selectedJobBreakupDetailId}
                job={job}
              ></LiveTracking>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default ContainerDetails;
