import React, { useEffect, useState } from "react";
import {
  JobBreakUpDetail,
  JobOrder,
  TripContainer,
} from "../../interfaces/job.interface";
import { containerSizeStringToNumber } from "../../utils/general";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./JobBreakupTable.scss";
import JobService from "../../services/JobService";
import { useLoading } from "../../contexts/LoadingContext";
import { showToast } from "../Toaster";
import * as XLSX from "xlsx";
import Modal from "../Modal/Modal";
import { Container } from "../../interfaces/container.interface";

interface JobBreakupTableProps {
  job: JobOrder;
  onSave: (data: JobOrder) => void;
  onCancel: () => void;
  excelImportedData?: Container[];
}

// Validation schema for container numbers
const validationSchema = Yup.array().of(
  Yup.array().of(
    Yup.object().shape({
      containerNumber: Yup.string()
        .matches(/^[A-Z]{4}\d{7}$/, "Invalid Container Number")
        .max(11, "Cannot exceed 11 characters")
        .nullable(),
    })
  )
);

const JobBreakupTable: React.FC<JobBreakupTableProps> = ({
  job,
  onSave,
  onCancel,
  excelImportedData,
}) => {
  // State for tracking original and edited data separately
  const [originalJobBreakupDetails, setOriginalJobBreakupDetails] = useState<
    JobBreakUpDetail[][]
  >(job.jobBreakups.map((b) => b.jobBreakUpDetails));
  const [editedJobBreakupDetails, setEditedJobBreakupDetails] = useState<
    JobBreakUpDetail[][]
  >(JSON.parse(JSON.stringify(originalJobBreakupDetails))); // Deep copy to avoid mutation
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedData, setUploadedData] = useState<any[]>([]);
  const [uploadedJobCode, setUploadedJobCode] = useState("");
  const loader = useLoading();

  // Formik setup
  const formik = useFormik({
    initialValues: editedJobBreakupDetails,
    validationSchema,
    onSubmit: () => {
      onSave(job); // Save only if form is valid
    },
    validateOnChange: false,
    validateOnBlur: true,
  });

  // Stats calculation function
  const calculateStats = (details: JobBreakUpDetail[][]) => {
    const totalContainers = details.flat().length;
    const assignedContainers = details
      .flat()
      .filter((detail) => detail.containerNumber).length;
    const pendingContainers = totalContainers - assignedContainers;

    return {
      totalContainers,
      assignedContainers,
      pendingContainers,
    };
  };
  const [stats, setStats] = useState(calculateStats(originalJobBreakupDetails));

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setUploadedData([]);
    setUploadedJobCode("");
  };

  const integrateUploadedData = () => {
    // console.log(uploadedData);
    setUploadedData([...uploadedData]);
    processUploadedData();
    showToast("Data integrated successfully!", "success");
    handleCloseModal();
  };

  const isIntegrateButtonEnabled = () => {
    return (
      uploadedJobCode.trim() !== "" &&
      uploadedJobCode === job.jobCode.trim().replace(/#/g, "").toUpperCase()
    );
  };

  const processUploadedData = () => {
    if (uploadedData.length === 0) return;

    // Track used container numbers to ensure uniqueness
    const usedContainerNumbers = new Set<string>();

    // Populate usedContainerNumbers with already assigned container numbers
    editedJobBreakupDetails.forEach((breakupDetails) =>
      breakupDetails.forEach((detail) => {
        if (detail.containerNumber) {
          usedContainerNumbers.add(detail.containerNumber);
        }
      })
    );

    // Create updated details by mapping over `editedJobBreakupDetails`
    const updatedDetails = editedJobBreakupDetails.map((breakupDetails) =>
      breakupDetails.map((detail) => {
        // Skip updating if container number is already assigned
        if (detail.containerNumber) return detail;

        // Find an unassigned matching container in `uploadedData`
        const match = uploadedData.find((data) => {
          return (
            data.containerSize ===
              containerSizeStringToNumber(detail.containerSize) &&
            data.containerType === detail.containerType &&
            !usedContainerNumbers.has(data.containerNumber)
          );
        });

        // If a match is found, set container number and mark it as used
        if (match) {
          usedContainerNumbers.add(match.containerNumber);
          return { ...detail, containerNumber: match.containerNumber };
        }

        return detail; // Return original detail if no match found
      })
    );

    // Check if `updatedDetails` is different from `editedJobBreakupDetails` to avoid unnecessary updates
    const isSame =
      JSON.stringify(editedJobBreakupDetails) ===
      JSON.stringify(updatedDetails);

    if (!isSame) {
      setEditedJobBreakupDetails(updatedDetails);
      formik.setValues(updatedDetails); // Update Formik values if details changed
    }
  };

  // Update the edited details on container number change
  const handleContainerNumberChange = (
    jobBreakupIndex: number,
    detailIndex: number,
    value: string
  ) => {
    const formattedValue = value.toUpperCase();
    const updatedDetails = [...editedJobBreakupDetails];
    updatedDetails[jobBreakupIndex][detailIndex].containerNumber =
      formattedValue;

    // Update Formik's state
    setEditedJobBreakupDetails(updatedDetails);
    formik.setFieldValue(
      `${jobBreakupIndex}[${detailIndex}].containerNumber`,
      formattedValue
    );

    // Revalidate the specific field and clear error if valid
    if (/^[A-Z]{4}\d{7}$/.test(formattedValue)) {
      formik.setFieldError(
        `${jobBreakupIndex}[${detailIndex}].containerNumber`,
        ""
      );
    }
  };

  function generateTripContainerList(): TripContainer[] {
    return editedJobBreakupDetails.flatMap((breakupDetails, breakupIndex) =>
      breakupDetails
        .filter(
          (detail, detailIndex) =>
            detail.containerNumber &&
            !originalJobBreakupDetails[breakupIndex][detailIndex]
              .containerNumber
        )
        .map((detail) => ({
          tripId: detail.jobBreakupDetailId,
          containerNumber: detail.containerNumber as string,
        }))
    );
  }

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.name.split(".").pop()?.toLowerCase();

      if (fileType === "xlsx" || fileType === "xls") {
        // Handle Excel file upload
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const rows = XLSX.utils.sheet_to_json<any>(worksheet, { header: 1 });

          const parsedJobCode = rows[1]?.[0]
            ?.toString()
            .trim()
            .replace(/#/g, "")
            .toUpperCase(); // Remove any '#' characters
          console.log("Parsed Job Code:", parsedJobCode);
          // Job Code in the first column (index 0)
          console.log("Parsed Job Code:", parsedJobCode);

          setUploadedJobCode(parsedJobCode);
          console.log("Uploaded Job Code ", uploadedJobCode);

          const parsedData = rows.slice(1).map((row: any) => ({
            //jobCode: row[0]?.toString().trim(), // Job Code from the first column
            containerNumber: row[1]?.toString().trim(), // Container Number from the second column
            containerSize: parseInt(row[2], 10), // Size from the third column
            containerType: row[3]?.toString().replace(/\r/g, "").trim(), // Type from the fourth column
          }));

          setUploadedData(parsedData);
          console.log(parsedData);
        };
        reader.readAsArrayBuffer(file);
      } else if (fileType === "csv") {
        // Handle CSV file upload
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target?.result as string;
          const rows = text.split("\n").map((row) => row.split(","));

          const parsedJobCode = rows[1]?.[0]
            ?.toString()
            .trim()
            .replace(/#/g, "")
            .toUpperCase(); // Remove any '#' characters
          setUploadedJobCode(parsedJobCode);
          console.log("Uploaded Job Code ", uploadedJobCode);

          const parsedData = rows.slice(1).map((row: any) => ({
            //jobCode: row[0]?.toString().trim(), // Job Code from the first column
            containerNumber: row[1]?.toString().trim(), // Container Number from the second column
            containerSize: parseInt(row[2], 10), // Size from the third column
            containerType: row[3]?.toString().replace(/\r/g, "").trim(), // Type from the fourth column
          }));

          setUploadedData(parsedData);
          console.log(parsedData);
        };
        reader.readAsText(file);
      } else {
        showToast(
          "Unsupported file format. Please upload an Excel or CSV file.",
          "error"
        );
      }

      // setIsModalOpen(true);

      // // Clear file input
      // event.target.value = "";
    }
  };

  useEffect(() => {
    console.log("Uploaded Job Code:", uploadedJobCode);
  }, [uploadedJobCode]);

  useEffect(() => {
    if (uploadedJobCode) {
      if (uploadedJobCode === job.jobCode) {
        showToast("Job Code matches successfully!", "success");
      } else {
        showToast(
          `Job Code mismatch! Uploaded: ${uploadedJobCode}, Expected: ${job.jobCode}`,
          "error"
        );
      }
    }
  }, [uploadedJobCode, job.jobCode]);

  const handleSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length === 0) {
      const tripList = generateTripContainerList();
      if (tripList.length > 0) {
        try {
          loader.setLoading(true);
          const res = await JobService.assignContainers(tripList);
          const updatedJob = res.data.data;
          showToast(
            `Successfully assigned the containers to Job #${job.jobCode}`,
            "success"
          );
          onSave(updatedJob);
          const updatedStats = calculateStats(editedJobBreakupDetails);
          setStats(updatedStats); // Update stats state
          // setOriginalJobBreakupDetails(editedJobBreakupDetails);
          closeModal();
        } catch (error) {
          console.error("Error assigning containers", error);
          showToast(
            `Failed to assign the containers, please try again !`,
            "error"
          );
        } finally {
          setTimeout(() => {
            loader.setLoading(false);
          }, 1000);
        }
      } else {
        closeModal();
      }
    } else {
      console.log("Invalid form", errors);
      showToast(`Please provide valid container numbers !`, "error");
    }
  };

  const closeModal = () => {
    onCancel();
  };

  useEffect(() => {
    console.log("excelImportedData");
    console.log(excelImportedData);
    if (!excelImportedData) return;
    if (excelImportedData.length === 0) return;
    let count = 0;

    // Track used container numbers to ensure uniqueness
    const usedContainerNumbers = new Set<string>();

    // Populate usedContainerNumbers with already assigned container numbers
    editedJobBreakupDetails.forEach((breakupDetails) =>
      breakupDetails.forEach((detail) => {
        if (detail.containerNumber) {
          usedContainerNumbers.add(detail.containerNumber);
        }
      })
    );

    // Create updated details by mapping over `editedJobBreakupDetails`
    const updatedDetails = editedJobBreakupDetails.map((breakupDetails) =>
      breakupDetails.map((detail) => {
        // console.log(detail);
        // Skip updating if container number is already assigned
        if (detail.containerNumber) return detail;

        // Find an unassigned matching container in `excelImportedData`
        const match = excelImportedData.find((data: any) => {
          return (
            data.containerSize ===
              containerSizeStringToNumber(detail.containerSize) &&
            data.containerType.toUpperCase() === detail.containerType &&
            !usedContainerNumbers.has(data.containerNumber.toUpperCase())
          );
        });

        // If a match is found, set container number and mark it as used
        if (match) {
          count++;
          usedContainerNumbers.add(match.containerNumber.toUpperCase());
          return {
            ...detail,
            containerNumber: match.containerNumber.toUpperCase(),
          };
        }

        return detail; // Return original detail if no match found
      })
    );

    // Check if `updatedDetails` is different from `editedJobBreakupDetails` to avoid unnecessary updates
    const isSame =
      JSON.stringify(editedJobBreakupDetails) ===
      JSON.stringify(updatedDetails);

    if (!isSame) {
      setEditedJobBreakupDetails(updatedDetails);
      formik.setValues(updatedDetails); // Update Formik values if details changed
    }

    if (count > 0) {
      showToast(`Imported ${count} container numbers`, "success");
    } else {
      showToast(`No container numbers were imported`, "warning");
    }
  }, [excelImportedData]);

  return (
    <div className="job-breakup">
      <form>
        <div className="breakup-table-container">
          <table>
            <thead>
              <tr>
                <th className="sl_no">Sl No.</th>
                <th className="container_no">Container Number</th>
                <th className="sity">Size & Type</th>
                <th className="transporter_name">Transporter</th>
              </tr>
            </thead>
            <tbody>
              {job.jobBreakups.map((breakup, breakupIndex) => {
                const offset = job.jobBreakups
                  .slice(0, breakupIndex)
                  .reduce(
                    (total, current) =>
                      total + current.jobBreakUpDetails.length,
                    0
                  );
                return editedJobBreakupDetails[breakupIndex].map(
                  (detail, detailIndex) => (
                    <tr key={detail.jobBreakupDetailId}>
                      {/* Calculate serial number using offset */}
                      <td className="sl_no">{offset + detailIndex + 1}</td>
                      <td className="container_no">
                        <input
                          maxLength={11}
                          type="search"
                          value={detail.containerNumber || ""}
                          onChange={(e) =>
                            handleContainerNumberChange(
                              breakupIndex,
                              detailIndex,
                              e.target.value
                            )
                          }
                          placeholder="Enter Container Number"
                          disabled={
                            !!originalJobBreakupDetails[breakupIndex][
                              detailIndex
                            ].containerNumber // Disable input only if originally filled
                          }
                        />
                        {formik.errors &&
                          (formik.errors[breakupIndex] as any)?.[detailIndex]
                            ?.containerNumber && (
                            <div className="validation_error">
                              {
                                (formik.errors[breakupIndex] as any)[
                                  detailIndex
                                ].containerNumber
                              }
                            </div>
                          )}
                      </td>
                      <td className="sity">
                        {containerSizeStringToNumber(detail.containerSize)}{" "}
                        {detail.containerType}
                      </td>
                      <td>{detail.transporterIdName.name || "N/A"}</td>
                    </tr>
                  )
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="w-full flex justify-center mt-[20px] gap-[20px]">
          <button
            type="button"
            className="secondary_button w-[128px]"
            onClick={closeModal}
          >
            Cancel
          </button>

          <button
            type="button"
            onClick={handleSave}
            className="primary_button w-[128px]"
          >
            Save
          </button>
        </div>
      </form>

      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title={`Import via Excel for #${job.jobCode}`}
        className="component_modal_container"
      >
        <div className="import_excel_component">
          <div className="upload_area">
            <input
              type="file"
              onChange={handleFileUpload}
              accept=".xlsx, .xls, .csv"
            />
          </div>

          {/* <h3 className="text-lg font-semibold text-gray-800 py-2">
            <span className="text-blue-600">#{uploadedJobCode}</span>
          </h3> */}

          {uploadedJobCode && (
            <div className="flex justify-center p-[10px]">
              <p
                className={`text-sm font-medium ${
                  uploadedJobCode === job.jobCode
                    ? "text-green-600"
                    : "text-red-500"
                }`}
              >
                {uploadedJobCode === job.jobCode ? "✔ " : "✘ "}#
                {uploadedJobCode}
              </p>
            </div>
          )}

          {/* Data Table with Scrolling */}
          {uploadedData.length > 0 ? (
            <div className="overflow-auto max-h-80 border rounded-lg shadow-sm">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 sticky top-0 z-10">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                    >
                      Sl. No.
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                    >
                      Container Number
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                    >
                      Size
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                    >
                      Type
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {uploadedData.map((data: any, index: any) => (
                    <tr key={index}>
                      <td className="px-6 py-4 text-sm text-gray-700 text-center">
                        {index + 1}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-700">
                        {data.containerNumber}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-700">
                        {data.containerSize}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-700">
                        {data.containerType}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-[11px] text-gray-400 mt-2">
              Please upload an Excel file (.xlsx) to import container data
            </p>
          )}

          {/* Action Buttons */}
          <div className="w-full flex justify-center mt-[20px] gap-[20px]">
            <button
              type="button"
              onClick={handleCloseModal}
              className="secondary_button w-[128px]"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={integrateUploadedData}
              disabled={!isIntegrateButtonEnabled()}
              className="primary_button  w-[128px]"
            >
              Import
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default JobBreakupTable;
