import React, { useState } from "react";

import CompleteJobDetail from "./CompleteJobDetail";
import CompleteTransporterDetail from "./CompleteTransporterDetail";
import CompleteContainer from "./CompleteContainer";
import { useParams } from "react-router-dom";

const samplejob = [
  {
    jobId: "17E888",
    sourceName: "Mumbai, Maharashtra, India",
    destinationName: "Goa, India",
    sourceLatitude: 19.0759837,
    sourceLongitude: 72.8776559,
    destinationLatitude: 15.2993265,
    destinationLongitude: 74.123996,
    movementCycle: "Export",
    vesselDetails: {
      vessel: "Beijing",
      service: "CIS",
      voa: "CSS",
      voyvia: "102/Q0449",
      gateopendatetime: 1721916780000,
      cutoffdatetime: 1721816220000,
      count: "60",
      size: "60",
      type: "FL",
      inventoryDetails: [
        {
          id: 1,
          count: "100",
          size: "40",
          type: "OT",
        },
        {
          id: 2,
          count: "90",
          size: "20",
          type: "HQ",
        },
        {
          id: 3,
          count: "60",
          size: "60",
          type: "FL",
        },
      ],
    },
    selectedTransporterIds: [1, 2, 3],
    jobStatus: "In Progress",
    containerDetails: [
      {
        containerNumber: "ABC1234567",
        size: "40",
        type: "OT",
        priority: "High",
        truckNumber: "MH12AB1234",
        transporter: "Transporter A",
        dateTime: 1721877000000,
        status: "In Transit",
        tracking: {
          TrackingId: "0001",
          TruckLatitude: 18.0, // Dynamically calculated between source & destination
          TruckLongitude: 73.0, // Dynamically calculated between source & destination
          ETA: "2 hours", // Can be calculated based on some logic
        },
      },
      {
        containerNumber: "DEF7654321",
        size: "20",
        type: "HQ",
        priority: "Medium",
        truckNumber: "MH12XY7890",
        transporter: "Transporter B",
        dateTime: 1721888400000,
        status: "In Transit",
        tracking: {
          TrackingId: "0002",
          TruckLatitude: 17.76,
          TruckLongitude: 73.19,
          ETA: "2 hours",
        },
      },
      {
        containerNumber: "DEF7654333",
        size: "20",
        type: "HQ",
        priority: "Low",
        truckNumber: "MH12XY7890",
        transporter: "Transporter B",
        dateTime: 1721888400000,
        status: "Pending",
      },
    ],
  },
  {
    jobId: "18F999",
    sourceName: "Chennai, Tamil Nadu, India",
    destinationName: "Kochi, Kerala, India",
    sourceLatitude: 13.0826802,
    sourceLongitude: 80.2707184,
    destinationLatitude: 9.9312328,
    destinationLongitude: 76.2673041,
    movementCycle: "Import",
    vesselDetails: {
      vessel: "Tokyo",
      service: "APL",
      voa: "APC",
      voyvia: "104/B0123",
      gateopendatetime: 1722916780000,
      cutoffdatetime: 1722816220000,
      count: "50",
      size: "40",
      type: "HQ",
      inventoryDetails: [
        {
          id: 1,
          count: "70",
          size: "20",
          type: "HQ",
        },
        {
          id: 2,
          count: "60",
          size: "40",
          type: "OT",
        },
      ],
    },
    selectedTransporterIds: [1, 4, 6],
    jobStatus: "Completed",
    containerDetails: [
      {
        containerNumber: "XYZ7890123",
        size: "40",
        type: "HQ",
        priority: "Low",
        truckNumber: "TN12AB1234",
        transporter: "Transporter C",
        dateTime: 1721970000000,
        status: "Delivered",
      },
    ],
  },
  {
    jobId: "19G000",
    sourceName: "Kolkata, West Bengal, India",
    destinationName: "Visakhapatnam, Andhra Pradesh, India",
    sourceLatitude: 22.572646,
    sourceLongitude: 88.363895,
    destinationLatitude: 17.6868159,
    destinationLongitude: 83.2184815,
    movementCycle: "General",
    vesselDetails: {
      vessel: "Shanghai",
      service: "MSK",
      voa: "MSS",
      voyvia: "106/A0901",
      gateopendatetime: 1723916780000,
      cutoffdatetime: 1723816220000,
      count: "30",
      size: "20",
      type: "OT",
      inventoryDetails: [
        {
          id: 1,
          count: "40",
          size: "20",
          type: "HQ",
        },
      ],
    },
    selectedTransporterIds: [1, 2],
    jobStatus: "Draft",
    containerDetails: [
      {
        containerNumber: "KLM1234567",
        size: "20",
        type: "HQ",
        priority: "Medium",
        truckNumber: "WB12XY7890",
        transporter: "Transporter D",
        dateTime: 1722062400000,
        status: "Awaiting Dispatch",
      },
    ],
  },
  {
    jobId: "20H111",
    sourceName: "Delhi, India",
    destinationName: "Jaipur, Rajasthan, India",
    sourceLatitude: 28.7040592,
    sourceLongitude: 77.1024902,
    destinationLatitude: 26.9124336,
    destinationLongitude: 75.7872709,
    movementCycle: "Export",
    vesselDetails: {
      vessel: "Mumbai",
      service: "EMC",
      voa: "EMS",
      voyvia: "108/A5678",
      gateopendatetime: 1724916780000,
      cutoffdatetime: 1724816220000,
      count: "80",
      size: "60",
      type: "HQ",
      inventoryDetails: [
        {
          id: 1,
          count: "50",
          size: "40",
          type: "FL",
        },
        {
          id: 2,
          count: "70",
          size: "20",
          type: "HQ",
        },
      ],
    },
    selectedTransporterIds: [1, 6, 7],
    jobStatus: "Cancelled",
    containerDetails: [
      {
        containerNumber: "NOP1234567",
        size: "60",
        type: "HQ",
        priority: "High",
        truckNumber: "DL12XY7890",
        transporter: "Transporter E",
        dateTime: 1722124800000,
        status: "Cancelled",
      },
    ],
  },
  {
    jobId: "20H112",
    sourceName: "Delhi, India",
    destinationName: "Jaipur, Rajasthan, India",
    sourceLatitude: 28.7040592,
    sourceLongitude: 77.1024902,
    destinationLatitude: 26.9124336,
    destinationLongitude: 75.7872709,
    movementCycle: "Export",
    vesselDetails: {
      vessel: "Mumbai",
      service: "EMC",
      voa: "EMS",
      voyvia: "108/A5678",
      gateopendatetime: 1724916780000,
      cutoffdatetime: 1724816220000,
      count: "80",
      size: "60",
      type: "HQ",
      inventoryDetails: [
        {
          id: 1,
          count: "50",
          size: "40",
          type: "FL",
        },
        {
          id: 2,
          count: "70",
          size: "20",
          type: "HQ",
        },
      ],
    },
    selectedTransporterIds: [1, 6, 7],
    jobStatus: "Cancelled",
    containerDetails: [
      {
        containerNumber: "QRS9876543",
        size: "40",
        type: "FL",
        priority: "Medium",
        truckNumber: "DL12AB4321",
        transporter: "Transporter F",
        dateTime: 1722128400000,
        status: "Cancelled",
      },
    ],
  },
  {
    jobId: "21I222",
    sourceName: "Ahmedabad, Gujarat, India",
    destinationName: "Surat, Gujarat, India",
    sourceLatitude: 23.022505,
    sourceLongitude: 72.5713621,
    destinationLatitude: 21.1702401,
    destinationLongitude: 72.8310607,
    movementCycle: "Export",
    vesselDetails: {
      vessel: "Hong Kong",
      service: "SITC",
      voa: "SIC",
      voyvia: "110/B5678",
      gateopendatetime: 1725916780000,
      cutoffdatetime: 1725816220000,
      count: "20",
      size: "20",
      type: "OT",
      inventoryDetails: [
        {
          id: 1,
          count: "30",
          size: "40",
          type: "OT",
        },
      ],
    },
    selectedTransporterIds: [1, 3, 9],
    jobStatus: "In Progress",
    containerDetails: [
      {
        containerNumber: "TUV9876543",
        size: "20",
        type: "OT",
        priority: "Low",
        truckNumber: "GJ12XY7890",
        transporter: "Transporter G",
        dateTime: 1722132000000,
        status: "In Transit",
      },
    ],
  },
  {
    jobId: "22J333",
    sourceName: "Hyderabad, Telangana, India",
    destinationName: "Bangalore, Karnataka, India",
    sourceLatitude: 17.385044,
    sourceLongitude: 78.486671,
    destinationLatitude: 12.9715987,
    destinationLongitude: 77.5945627,
    movementCycle: "Import",
    vesselDetails: {
      vessel: "Seoul",
      service: "KMTC",
      voa: "KMC",
      voyvia: "112/C7890",
      gateopendatetime: 1726916780000,
      cutoffdatetime: 1726816220000,
      count: "60",
      size: "40",
      type: "HQ",
      inventoryDetails: [
        {
          id: 1,
          count: "70",
          size: "20",
          type: "FL",
        },
      ],
    },
    selectedTransporterIds: [2, 3, 8],
    jobStatus: "Completed",
    containerDetails: [
      {
        containerNumber: "WXY6543210",
        size: "40",
        type: "HQ",
        priority: "High",
        truckNumber: "TS12AB4321",
        transporter: "Transporter H",
        dateTime: 1722177600000,
        status: "Delivered",
      },
    ],
  },
  {
    jobId: "23K444",
    sourceName: "Pune, Maharashtra, India",
    destinationName: "Nagpur, Maharashtra, India",
    sourceLatitude: 18.5204303,
    sourceLongitude: 73.8567437,
    destinationLatitude: 21.1458004,
    destinationLongitude: 79.0881546,
    movementCycle: "Export",
    vesselDetails: {
      vessel: "Hamburg",
      service: "ONE",
      voa: "ONS",
      voyvia: "114/D1234",
      gateopendatetime: 1727916780000,
      cutoffdatetime: 1727816220000,
      count: "40",
      size: "40",
      type: "OT",
      inventoryDetails: [
        {
          id: 1,
          count: "60",
          size: "40",
          type: "HQ",
        },
        {
          id: 2,
          count: "50",
          size: "60",
          type: "FL",
        },
      ],
    },
    selectedTransporterIds: [4, 5, 9],
    jobStatus: "In Progress",
    containerDetails: [
      {
        containerNumber: "ZAB6543210",
        size: "40",
        type: "OT",
        priority: "High",
        truckNumber: "MH12AB9876",
        transporter: "Transporter I",
        dateTime: 1722184800000,
        status: "In Transit",
      },
    ],
  },
  {
    jobId: "24L555",
    sourceName: "Lucknow, Uttar Pradesh, India",
    destinationName: "Kanpur, Uttar Pradesh, India",
    sourceLatitude: 26.8467088,
    sourceLongitude: 80.9461592,
    destinationLatitude: 26.449923,
    destinationLongitude: 80.3318736,
    movementCycle: "Import",
    vesselDetails: {
      vessel: "Antwerp",
      service: "COSCO",
      voa: "CNS",
      voyvia: "116/E5678",
      gateopendatetime: 1728916780000,
      cutoffdatetime: 1728816220000,
      count: "50",
      size: "20",
      type: "FL",
      inventoryDetails: [
        {
          id: 1,
          count: "40",
          size: "20",
          type: "HQ",
        },
      ],
    },
    selectedTransporterIds: [6, 7, 8],
    jobStatus: "Completed",
    containerDetails: [
      {
        containerNumber: "CDE6543210",
        size: "20",
        type: "FL",
        priority: "Medium",
        truckNumber: "UP12XY9876",
        transporter: "Transporter J",
        dateTime: 1722200400000,
        status: "Delivered",
      },
    ],
  },
  {
    jobId: "25M666",
    sourceName: "Bhopal, Madhya Pradesh, India",
    destinationName: "Indore, Madhya Pradesh, India",
    sourceLatitude: 23.2599333,
    sourceLongitude: 77.412615,
    destinationLatitude: 22.7195687,
    destinationLongitude: 75.8577258,
    movementCycle: "General",
    vesselDetails: {
      vessel: "Rotterdam",
      service: "HL",
      voa: "HLS",
      voyvia: "118/F1234",
      gateopendatetime: 1729916780000,
      cutoffdatetime: 1729816220000,
      count: "60",
      size: "40",
      type: "HQ",
      inventoryDetails: [
        {
          id: 1,
          count: "70",
          size: "20",
          type: "OT",
        },
      ],
    },
    selectedTransporterIds: [7, 9, 10],
    jobStatus: "Draft",
    containerDetails: [
      {
        containerNumber: "FGH4321098",
        size: "20",
        type: "OT",
        priority: "Low",
        truckNumber: "MP12XY5432",
        transporter: "Transporter K",
        dateTime: 1722207600000,
        status: "Awaiting Dispatch",
      },
    ],
  },
  {
    jobId: "26N777",
    sourceName: "Chandigarh, India",
    destinationName: "Shimla, Himachal Pradesh, India",
    sourceLatitude: 30.733315,
    sourceLongitude: 76.779418,
    destinationLatitude: 31.104814,
    destinationLongitude: 77.173403,
    movementCycle: "Export",
    vesselDetails: {
      vessel: "Osaka",
      service: "PIL",
      voa: "PIS",
      voyvia: "120/G1234",
      gateopendatetime: 1730916780000,
      cutoffdatetime: 1730816220000,
      count: "40",
      size: "20",
      type: "FL",
      inventoryDetails: [
        {
          id: 1,
          count: "50",
          size: "40",
          type: "HQ",
        },
      ],
    },
    selectedTransporterIds: [8, 10, 11],
    jobStatus: "In Progress",
    containerDetails: [
      {
        containerNumber: "JKL8765432",
        size: "20",
        type: "FL",
        priority: "High",
        truckNumber: "CH01XY7890",
        transporter: "Transporter L",
        dateTime: 1722214800000,
        status: "In Transit",
      },
    ],
  },
  {
    jobId: "27O888",
    sourceName: "Ranchi, Jharkhand, India",
    destinationName: "Patna, Bihar, India",
    sourceLatitude: 23.3440997,
    sourceLongitude: 85.309562,
    destinationLatitude: 25.5940947,
    destinationLongitude: 85.1375645,
    movementCycle: "Import",
    vesselDetails: {
      vessel: "Brisbane",
      service: "WANHAI",
      voa: "WHS",
      voyvia: "122/H5678",
      gateopendatetime: 1731916780000,
      cutoffdatetime: 1731816220000,
      count: "30",
      size: "40",
      type: "HQ",
      inventoryDetails: [
        {
          id: 1,
          count: "40",
          size: "20",
          type: "OT",
        },
      ],
    },
    selectedTransporterIds: [9, 10, 12],
    jobStatus: "Completed",
    containerDetails: [
      {
        containerNumber: "MNO1234567",
        size: "40",
        type: "HQ",
        priority: "Medium",
        truckNumber: "JH01XY5432",
        transporter: "Transporter M",
        dateTime: 1722222000000,
        status: "Delivered",
      },
    ],
  },
  {
    jobId: "28P999",
    sourceName: "Guwahati, Assam, India",
    destinationName: "Shillong, Meghalaya, India",
    sourceLatitude: 26.1445169,
    sourceLongitude: 91.7362365,
    destinationLatitude: 25.578772,
    destinationLongitude: 91.893254,
    movementCycle: "General",
    vesselDetails: {
      vessel: "Sydney",
      service: "GSL",
      voa: "GSS",
      voyvia: "124/I1234",
      gateopendatetime: 1732916780000,
      cutoffdatetime: 1732816220000,
      count: "20",
      size: "20",
      type: "OT",
      inventoryDetails: [
        {
          id: 1,
          count: "30",
          size: "20",
          type: "HQ",
        },
      ],
    },
    selectedTransporterIds: [10, 11, 13],
    jobStatus: "Draft",
    containerDetails: [
      {
        containerNumber: "PQR9876543",
        size: "20",
        type: "OT",
        priority: "Low",
        truckNumber: "AS01XY0987",
        transporter: "Transporter N",
        dateTime: 1722229200000,
        status: "Awaiting Dispatch",
      },
    ],
  },
];

const IndividualJob = () => {
  const { jobId } = useParams();
  const [activeTab, setActiveTab] = useState("JobSummary");
  const job = samplejob.find((job) => job.jobId === jobId);

  // If the job is not found, return a message or handle the case accordingly
  if (!job) {
    return <div>Job not found</div>;
  }

  return (
    <div className="mt-6 p-4 bg-gray-100 rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Job ID: {job.jobId}</h2>

      {/* Tab Buttons */}
      <div className="flex mb-4 border-b border-bottomline">
        <button
          className={`px-4 py-2 mr-2 font-bold ${
            activeTab === "JobSummary"
              ? "border-b border-blueText text-blueText"
              : "text-notSelected"
          }`}
          onClick={() => setActiveTab("JobSummary")}
        >
          Job Summary
        </button>
        <button
          className={`px-4 py-2 font-bold ${
            activeTab === "Container"
              ? "border-b border-blueText text-blueText"
              : "text-notSelected"
          }`}
          onClick={() => setActiveTab("Container")}
        >
          Container
        </button>
      </div>
      {/* Display content based on active tab */}
      {activeTab === "JobSummary" ? (
        <div className="p-4 bg-white rounded">
          {/* Flexbox layout for JobDetails and TransporterDetails */}
          <div className="flex flex-wrap -mx-2">
            <div className="w-1/2 h-screen overflow-y-auto p-4 bg-gray-100">
              <CompleteJobDetail job={job} />
            </div>

            <div className="w-1/2 h-screen overflow-y-auto p-4 bg-white">
              <CompleteTransporterDetail
                selectedTransporterIds={job.selectedTransporterIds}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="p-4 bg-white rounded">
          <CompleteContainer selectedJob={job} />
        </div>
      )}


      
    </div>
  );
};

export default IndividualJob;
