import * as XLSX from "xlsx";

// Types for better type safety
interface ParsedData {
  containerNumber: string;
  containerSize: number;
  containerType: string;
}

interface FileParsingResult {
  jobCode: string;
  data: ParsedData[];
}

// Utility function to parse job code from row
const parseJobCode = (row: any): string => {
  return row?.[0]?.toString().trim().replace(/#/g, "").toUpperCase() || "";
};

// Utility function to parse a single row of data
const parseDataRow = (row: any): ParsedData => {
  return {
    containerNumber: row[1]?.toString().trim() || "",
    containerSize: parseInt(row[2], 10) || 0,
    containerType: row[3]?.toString().replace(/\r/g, "").trim() || "",
  };
};

// Function to handle Excel file parsing
const parseExcelFile = (
  arrayBuffer: ArrayBuffer
): Promise<FileParsingResult> => {
  return new Promise((resolve) => {
    const data = new Uint8Array(arrayBuffer);
    const workbook = XLSX.read(data, { type: "array" });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const rows = XLSX.utils.sheet_to_json<any>(worksheet, { header: 1 });

    const jobCode = parseJobCode(rows[1]);
    const parsedData = rows.slice(1).map(parseDataRow);

    resolve({ jobCode, data: parsedData });
  });
};

// Function to handle CSV file parsing
const parseCsvFile = (text: string): FileParsingResult => {
  const rows = text.split("\n").map((row) => row.split(","));
  const jobCode = parseJobCode(rows[1]);
  const parsedData = rows.slice(1).map(parseDataRow);

  return { jobCode, data: parsedData };
};

// Main utility function to handle file uploads
export const handleExcelFileUpload = async (
  file: File,
  onSuccess: (result: FileParsingResult) => void,
  onError: (message: string) => void
): Promise<void> => {
  const fileType = file.name.split(".").pop()?.toLowerCase();

  try {
    if (fileType === "xlsx" || fileType === "xls") {
      const arrayBuffer = await file.arrayBuffer();
      const result = await parseExcelFile(arrayBuffer);
      onSuccess(result);
    } else if (fileType === "csv") {
      const text = await file.text();
      const result = parseCsvFile(text);
      onSuccess(result);
    } else {
      onError("Unsupported file format. Please upload an Excel or CSV file.");
    }
  } catch (error: unknown) {
    const errorMessage =
      error instanceof Error ? error.message : "An unknown error occurred";
    onError(`Error processing file: ${errorMessage}`);
  }
};

// Example usage in a React component:
/*
const YourComponent = () => {
  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    await handleExcelFileUpload(
      file,
      ({ jobCode, data }) => {
        setUploadedJobCode(jobCode);
        setUploadedData(data);
        console.log('Parsed data:', data);
      },
      (error) => {
        showToast(error, 'error');
      }
    );

    // Clear file input
    event.target.value = '';
  };

  return <input type="file" onChange={handleUpload} />;
};
*/
